import React, {useState} from "react";

import {useNavigate} from "react-router-dom";

import Form from "../../Components/Form";
import Input from "../../Components/Input";
import Select from "../../Components/Select";
import APIService from "../../services/api";
import {useSnackbar} from "../../hooks";

import './ProjectAdd.css';

const ProjectAdd = ({}) => {
  const [name, setName] = useState('');
  const [balance, setBalance] = useState('0');
  const [counterparty, setCounterparty] = useState(null);
  const [category, setCategory] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [tax, setTax] = useState(null);
  const [parent, setParent] = useState(null);
  const [members, setMembers] = useState(null);

  const [errors, setErrors] = useState(null);

  const navigate = useNavigate();
  const api = new APIService();
  const openSnackbar = useSnackbar();

  const handleFormSubmit = async () => {
    const projectData = {
      name,
      balance,
      currency,
      category: parent ? null : category,
      counterparty,
      tax,
      parent,
      members: members ? members.map(member => member.value) : null
    }
    let project = null;
    try {
      project = await api.createProject(projectData);
    } catch (e) {
      e.message ? openSnackbar(e.message) : setErrors(e.data);
    }

    project && navigate(`/project/${project.id}/transactions`);
  }

  return (
    <Form
      headerText='Створення нового проекту'
      submitButtonText='Створити'
      onSubmit={handleFormSubmit}
      error={errors && errors['non_field_errors']}
    >
      <div className="row">
        <div className="col">
          <Input
            value={name}
            type={'text'}
            placeholder='Назва проекту *'
            error={errors && errors['name']}
            onChange={e => setName(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Select
            isAsync
            isClearable
            isCreatable
            isSearchable
            placeholder='Контрагент *'
            error={errors && errors['counterparty']}
            getOptions={api.getCounterparties}
            onChange={option => setCounterparty(option ? option.value : null)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Select
            isAsync
            isSearchable
            placeholder='Батьківський проект'
            error={errors && errors['parent']}
            getOptions={api.getProjectsOptions}
            onChange={option => setParent(option ? option.value : null)}
          />
        </div>
      </div>
      {parent === null && (
        <div className="row">
          <div className="col">
            <Select
              isSearchable
              placeholder='Категорія *'
              error={errors && errors['category']}
              getOptions={api.getProjectCategories}
              onChange={option => setCategory(option ? option.value : null)}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">
          <Select
            isClearable
            placeholder='Валюта *'
            error={errors && errors['currency']}
            getOptions={api.getCurrencies}
            onChange={option => setCurrency(option ? option.value : null)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Input
            value={balance}
            type={'number'}
            placeholder='Початковий баланс'
            error={errors && errors['balance']}
            onChange={e => setBalance(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Select
            isClearable
            placeholder='Ставка податку'
            error={errors && errors['tax']}
            getOptions={api.getTaxRates}
            onChange={option => setTax(option ? option.value : null)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Select
            isMulti
            isSearchable
            placeholder='Учасники'
            error={null}
            getOptions={api.getUsers}
            onChange={options => setMembers(options)}
          />
        </div>
      </div>
    </Form>
  )
}

export default ProjectAdd;