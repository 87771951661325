import React, {memo} from "react";

import './ErrorText.css';

const ErrorText = ({text}) => (
    <p className='error-text'>
        <span>{text}</span>
    </p>
)

export default memo(ErrorText);