import React, {useContext, useMemo} from "react";

import Table from "../../../Components/Table";
import {ProjectContext} from "../../../context";

const TransactionReadOnlyTable = ({transactionAmount, transactionSellingAmount, transactionItems}) => {
    const {project} = useContext(ProjectContext);
    const {currency} = project;

    const sellingTotalAmount = useMemo(() => {
        const totalAmountReducer = (currentValue, transactionItem) => currentValue + parseFloat(transactionItem.selling_total_amount);
        if (!transactionItems) return 0;
        return transactionItems.reduce(totalAmountReducer, 0).toFixed(2);
    }, []);

    return (
        <Table
            rows={transactionItems}
            renderHead={() => (
                <tr>
                    <td>Товар/послуга</td>
                    <td>Ціна</td>
                    <td>Продажна ціна</td>
                    <td>Кількість</td>
                    <td>Націнка/знижка, %</td>
                    <td>Всього</td>
                </tr>
            )}
            renderRow={item => (
                <tr key={item.id}>
                    <td>{item.goods ? item.goods.label : item.name}</td>
                    <td>{item.amount} {currency}</td>
                    <td>{item.selling_amount} {currency}</td>
                    <td>{item.quantity}</td>
                    <td>{item.extra_charge_percentage}</td>
                    <td>
                      <div><b>{item.total_amount} {currency}</b></div>
                      <div><b className='total-selling-amount'>{item.selling_total_amount} {currency}</b></div>
                    </td>
                </tr>
            )}
            renderBottom={() => (
                <>
                    <tr>
                        <td colSpan={6}>
                            <b>Всього: {transactionAmount} {currency}</b>
                            <br/>
                            {sellingTotalAmount && <b className='total-selling-amount'>Продаж: {sellingTotalAmount} {currency}</b>}

                        </td>
                    </tr>
                </>
            )}
            renderEmpty={() => <tr><td colSpan={3}>-</td></tr>}
        />
    )
}

export default TransactionReadOnlyTable;
