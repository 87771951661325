import React from 'react';

const ProjectTransactionsPurchaseCells = ({transaction}) => {
  const onIdClick = (e) => e.stopPropagation();

  const incomeAmount = parseFloat(transaction.income_amount);
  const outcomeAmount = parseFloat(transaction.outcome_amount);
  const amountDiff = outcomeAmount - incomeAmount;

  return (
    <>
      <td onClick={onIdClick} style={{cursor: 'text'}}>{transaction.id}</td>
      <td>{transaction.date}</td>
      <td style={{overflow: 'hidden'}} title={transaction.description}>{transaction.description}</td>
      <td className='table-positive-value'>{incomeAmount.toFixed(2)}</td>
      <td className='table-negative-value'>{outcomeAmount.toFixed(2)}</td>
      <td>{transaction.manager}</td>
      <td>{transaction.status.label}</td>
      <td>
        {amountDiff <= 1 ?
        <span className='confirm-badge'>&#10003;</span> :
        <span className='not-confirm-badge'>&#10007;</span>}
      </td>
      <td>{transaction.is_confirmed ? <span className='confirm-badge'>&#10003;</span> :
        <span className='not-confirm-badge'>&#10007;</span>}</td>
    </>
  );
};

export default ProjectTransactionsPurchaseCells;