import React from 'react';
import Select from "../Select";

import APIService from "../../services/api";

export const UserSelect = ({user, onChange}) => {
  const api = new APIService();

  return (
    <Select
      isSmall
      isSearchable
      isClearable
      defaultValue={user}
      getOptions={api.getUsers}
      placeholder='Користувач'
      errors={null}
      onChange={option => onChange(option || null)}
    />
  );
};

export default React.memo(UserSelect);