import React, {useState, useEffect} from "react";

import {useParams} from "react-router-dom";

import Loader from "../../Components/Loader";
import ProjectTransactionsTable from './ProjectTransactionsTable';
import ProjectTransactionsMenu from './ProjectTransactionsMenu';
import TransactionsTotalAmount from './TransactionsTotalAmount';
import APIService from "../../services/api";
import {useSnackbar} from "../../hooks";

import './ProjectTransactions.css';

const ProjectTransactions = () => {
    const [transactions, setTransactions] = useState(null);
    const [filteredTransactions, setFilteredTransactions] = useState(null);
    const urlParams = useParams();
    const openSnackbar = useSnackbar();

    const api = new APIService();

    useEffect(() => {
        window.scrollTo(0, 0);

        getTransactions();
    }, []);

    const filterTransactions = ({searchValue}) => {
        if (!searchValue) return setFilteredTransactions(transactions);
        setFilteredTransactions(transactions.filter(t => {
            return `${t.id}`.includes(searchValue) || t.description?.includes(searchValue);
        }));
    }

    const getTransactions = async () => {
        let transactions;
        try {
            transactions = await api.getProjectTransactions(urlParams.projectId);
        } catch (e) {
            return openSnackbar(e.message);
        }
        setTransactions(transactions);
        setFilteredTransactions(transactions);
    }

    const exportProjectToExcel = async () => {
        api.exportProjectToExcel(urlParams.projectId)
          .then((blob) => {
            var _url = window.URL.createObjectURL(blob);
            window.open(_url, "_blank").focus();
        }).catch((e) => openSnackbar(e.message));
    }

    if (filteredTransactions === null) return <Loader/>;

    return (
        <>
            <ProjectTransactionsMenu
              onFiltersChange={filterTransactions}
              onExportToExcel={exportProjectToExcel}
            />
            <ProjectTransactionsTable transactions={filteredTransactions}/>
            <TransactionsTotalAmount />
        </>
    )
}

export default ProjectTransactions;
