import {useSnackbar as _useSnackbar} from 'react-simple-snackbar';

const useSnackbar = () => {
    const [openSnackbar] = _useSnackbar({
        position: 'top-center',
        style: {
            background: 'linear-gradient(88.88deg, #FF9D85 0%, #FFD585 100%)',
            color: 'black',
            fontFamily: 'Inter',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
            borderRadius: '12px',
        },
        closeStyle: {
            color: 'black',
            fontWeight: '700',
            fontSize: '20px',
        },
    });
    return openSnackbar;
}

export {
    useSnackbar,
}