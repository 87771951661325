import React, {memo} from 'react';

const ProjectListTreeView = ({level, projectName}) => {
    const offset = 24 * level;
    return (
        <div className='d-flex align-items-center' title={projectName}>
            <span>
                <div className='tree-node' style={{width: `${offset}px`}}/>
            </span>
            <span
                className={`project-name ${level === 0 ? 'main-project' : ''}`}
                style={{paddingLeft: `${level ? 16 : 0}px`}}
            >{projectName.slice(0, 80)}{projectName.length > 80 && '...'}</span>
        </div>
    );
};

const propsAreEqual = (prev, next) => {
    return prev.level === next.level;
}

export default memo(ProjectListTreeView, propsAreEqual);