import React, {useContext, memo} from 'react';
import {ProjectContext} from "../../../context";

const TransactionsTotalAmount = () => {
  const {project} = useContext(ProjectContext);

  return (
    <>
      <div class="row">
        <div class="col d-flex justify-content-end pt-4">
          <b className='mr-2'>Всього витрат:</b>
          <span className='table-negative-value'> {project.total_outcome} {project.currency}</span>
        </div>
      </div>
      <div className="row pb-4">
        <div className="col d-flex justify-content-end pt-2">
          <b className='mr-2'>Всього доходу:</b>
          <span class='table-positive-value'>{project.total_income} {project.currency}</span>
        </div>
      </div>
    </>
  );
};

export default memo(TransactionsTotalAmount);
