import React, {useState, useEffect} from "react";

import {useParams} from "react-router-dom";

import Table, {TableHead, TableRow} from "../../Components/Table";
import APIService from "../../services/api";
import {useSnackbar} from "../../hooks";
import Loader from "../../Components/Loader";

const ProjectHistory = () => {
    const [history, setHistory] = useState(null);

    const urlParams = useParams();
    const api = new APIService();
    const openSnackbar = useSnackbar();

    useEffect(() => {
        getHistory();
    }, []);

    const getHistory = async () => {
        try {
            setHistory(await api.getProjectHistory(urlParams.projectId));
        } catch (e) {
            openSnackbar(e.message);
        }
    }

    if (!history) return <Loader />

    return (
        <div className='container'>
            <div className="row">
                <div className="col">
                    <Table
                        rows={history}
                        renderHead={() => (
                            <tr>
                                <td>Користувач</td>
                                <td>Час</td>
                                <td>Дія</td>
                            </tr>
                        )}
                        renderRow={historyItem => (
                            <tr>
                                <td>{historyItem.user}</td>
                                <td>{historyItem.timestamp}</td>
                                <td>
                                    <div dangerouslySetInnerHTML={{__html: historyItem.log}}/>
                                </td>
                            </tr>
                        )}
                        renderEmpty={() => (
                            <tr>
                                <td colSpan={3}>Історія відсутня.</td>
                            </tr>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProjectHistory;