import React from 'react';

const ProjectTransactionsTableRowItems = ({items, isPurchase}) => (
  <>
    <tr>
      <td className='transactions-details'/>
      <td className='transactions-details transactions-details-header' colSpan={isPurchase ? 5 : 3}>Товар/послуга</td>
      <td className='transactions-details transactions-details-header' colSpan={2}>Ціна</td>
      <td className='transactions-details transactions-details-header'>Кількість</td>
      <td className='transactions-details transactions-details-header'>Всього</td>
    </tr>
    {items.map(ti => (
      <tr key={ti.id}>
        <td className='transactions-details'>
          <div className='tree-node'/>
        </td>
        <td className='transactions-details' colSpan={isPurchase ? 5 : 3}>{ti.goods ? ti.goods.label : ti.name}</td>
        <td className='transactions-details' colSpan={2}>{ti.amount}</td>
        <td className='transactions-details'>{ti.quantity}</td>
        <td className='transactions-details'>{ti.total_amount}</td>
      </tr>
    ))}
  </>
)

export default ProjectTransactionsTableRowItems;
