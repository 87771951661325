import React from "react";

import './Table.css';

const Table = ({rows, renderHead, renderRow, renderEmpty, renderBottom}) => (
    <div className='table-wrapper'>
        <table className="table">
            <thead>{renderHead && renderHead()}</thead>
            <tbody>
                {rows.length > 0 && rows.map(row => renderRow(row))}
                {!rows.length && renderEmpty && renderEmpty()}
                {renderBottom && renderBottom()}
            </tbody>
        </table>
    </div>
)

export default Table;
