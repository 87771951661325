import React from 'react';

import AttachmentItem from "./AttachmentItem";

const AttachmentsList = ({attachments, isReadOnly, onAttachmentDelete}) => {
  return (
    <>
      <div className='row'>
        <div className="col">
          {isReadOnly ? <h4>Вкладення</h4> : <h3>Вкладення</h3>}
        </div>
      </div>
      {attachments.map(attachment => (
        <AttachmentItem
          key={attachment.id}
          isReadOnly={isReadOnly}
          attachment={attachment}
          onAttachmentDelete={onAttachmentDelete}
        />
      ))}
    </>
  );
};

export default AttachmentsList;
