import React, {useState, useEffect, memo, useContext} from 'react';
import Input from "../../Components/Input";
import Select from "../../Components/Select";
import Button from "../../Components/Button";
import {UserContext} from "../../context";
import APIService from "../../services/api";

const projectStatuses = [
  {value: 'open', label: 'Відкриті'},
  {value: 'closed', label: 'Закриті'},
  {value: 'debt', label: 'Боржники'},
  {value: '*', label: 'Всі'},
]

const ProjectsMenu = ({searchValue, onSearchChange, onFiltersChange, onShowAddProjectModalButtonClick}) => {
  const [statusFilter, setStatusFilter] = useState('open');
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  const {user} = useContext(UserContext);
  const api = new APIService();

  useEffect(() => {
    onFiltersChange({searchValue, statusFilter, categoriesFilter});
  }, [statusFilter, categoriesFilter]);

  return (
    <div className="row mb-3">
      <div className="col-12 col-lg-4">
        <Input
          value={searchValue}
          type='text'
          placeholder='Пошук за номером чи назвою проекту'
          onChange={e => onSearchChange(e.target.value)}
        />
      </div>
      <div className="col-12 col-lg-2">
        <Select
          defaultValue={projectStatuses[0]}
          defaultOptions={projectStatuses}
          onChange={option => setStatusFilter(option.value)}
        />
      </div>
      <div className="col-12 col-lg-4">
        <Select
          isMulti
          isSearchable
          placeholder={'Категорія'}
          getOptions={api.getProjectCategories}
          onChange={options => setCategoriesFilter(options.map(o => o.value))}
        />
      </div>
      {user && user.permissions.add_projects && (
        <div className="col-12 col-lg-2">
          <Button
            text='Додати проект'
            onClick={onShowAddProjectModalButtonClick}
          />
        </div>
      )}
    </div>
  );
};

export default memo(ProjectsMenu);