import React, {memo} from "react";

import './Switch.css';

const Switch = ({label, checked, onChange}) => (
    <label className="switch">
        <input
            type="checkbox"
            checked={checked}
            onChange={event => onChange(event.target.checked)}
        />
        <span className="slider round" />
        <span className='label'>{label}</span>
    </label>
)

const propsAreEqual = (prev, next) => {
    return prev.checked === next.checked;
}
export default memo(Switch, propsAreEqual);