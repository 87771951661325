import React, {useState} from 'react';

import Button from "../Button";
import ErrorText from "../ErrorText";

import './Form.css';

const Form = ({children, headerText, submitButtonText, error, onSubmit}) => {
    const [isProcessing, setIsProcessing] = useState(false);

    const handleFormSubmit = (e) => {
        setIsProcessing(true);
        e.preventDefault();
        onSubmit();
        setIsProcessing(false);
    }

    return (
        <form className='form'>
            <h2 className='form--header'>{headerText}</h2>
            {children}
            {error && (
                <div className="row">
                    <div className="col">
                        <ErrorText text={error}/>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col">
                    <Button
                        disabled={isProcessing}
                        text={submitButtonText}
                        onClick={handleFormSubmit}
                    />
                </div>
            </div>
        </form>
    )
}

export default Form;