import React, {memo} from 'react';
import {IoIosArrowDropdownCircle} from "react-icons/io";

const ToggleTransactionRowDetailsButton = ({show, onClick}) => (
    <IoIosArrowDropdownCircle
        style={{fontSize: '24px', color: 'rgba(0, 0, 0, 0.2)', cursor: 'pointer'}}
        className={`toggle-icon ${show ? 'toggle-icon--active' : ''}`}
        onClick={onClick}
    />
);

export default memo(ToggleTransactionRowDetailsButton);
