import React, {memo} from 'react';

import './Breadcrumbs.css';

const Breadcrumbs = ({children}) => (
    <div className="col-12 mt-4 pl-2 breadcrumbs">
        <h4>
            {children}
        </h4>
    </div>
)

export default memo(Breadcrumbs, () => true);