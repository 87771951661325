import React, {useState, useEffect, memo} from 'react';

import Input from "../../Components/Input";
import {Dropdown, DropdownItem} from "../../Components/Dropdown";

const ProjectTransactionsMenu = ({onFiltersChange, onExportToExcel}) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    onFiltersChange({searchValue});
  }, [searchValue]);

  return (
    <div className="row d-flex justify-content-between">
      <div className="col-12 col-lg-4">
        <Input
          value={searchValue}
          placeholder={'Пошук'}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <div className="col-12 col-lg-2 d-flex justify-content-end">
        <Dropdown text={`\u2630`}>
          <DropdownItem text='Експорт в Excel' onClick={onExportToExcel}/>
        </Dropdown>
      </div>
    </div>
  );
};

export default memo(ProjectTransactionsMenu);
