import React, {useState, useContext} from 'react';

import {Link} from "react-router-dom";

import Input from "../../Components/Input";
import APIService from "../../services/api";
import {useSnackbar} from "../../hooks";
import TimesheetDayRowMenu from './TimesheetDayRowMenu';
import Select from "../../Components/Select";
import {UserContext} from "../../context";

const TimesheetDayRow = ({row, onEditTimesheet, isChild = false}) => {
    const api = new APIService();
    const openSnackbar = useSnackbar();

    const [timesheet, setTimesheet] = useState({hours: row.hours});
    const [errors, setErrors] = useState(null);

    const {user} = useContext(UserContext);

    const closeTimesheet = async () => {
        try {
            await api.editTimesheetItem(row.id, {...timesheet, is_closed: true});
        } catch (e) {
            e.data ? setErrors(e.data) : openSnackbar(e.message);
            return false;
        }

        setErrors(null);
        onEditTimesheet();
        setTimesheet({});
    }

    const openTimesheet = async () => {
        try {
            await api.editTimesheetItem(row.id, {is_closed: false});
        } catch (e) {
            e.data ? setErrors(e.data) : openSnackbar(e.message);
            return false;
        }

        setErrors(null);
        onEditTimesheet();
        setTimesheet({});
    }

    const splitTimesheet = async () => {
        try {
            await api.splitTimesheetItem(row.id);
        } catch (e) {
            e.data ? setErrors(e.data) : openSnackbar(e.message);
            return false;
        }
        setErrors(null);
        onEditTimesheet();
    }

    const isClosed = row.is_closed;
    const isManaged = user.id === row.manager?.value;
    return (
        <>
            <div className={`row timesheet-row ${isChild && 'timesheet-row--child'}`}>
                <div className={`col-3 d-flex justify-content-start ${!row.is_closed && 'pt-2'}`}>
                    {!isChild && <Link to={`/timesheet/worker/${row.worker.id}`}><b>{row.worker.name}</b></Link>}
                </div>
                <div className='col-1'>
                    {row.is_closed && <div className='d-flex justify-content-start pl-2 pb-3'>{row.hours}</div>}
                    {!row.is_closed && (<Input
                        value={timesheet.hours}
                        type={'text'}
                        placeholder="Години"
                        isDisabled={!isManaged}
                        error={errors && errors['hours']}
                        onChange={e => {
                            e.persist();
                            setTimesheet(t => ({...t, hours: e.target?.value || ''}))
                        }}
                    />)}
                </div>
                <div className="col-3">
                    {row.is_closed && <div className='d-flex justify-content-start'>{row.manager?.label || '-'}</div>}
                    {!row.is_closed && <Select
                        isAsync
                        isSearchable
                        placeholder='Відповідальний'
                        isDisabled={!isManaged}
                        error={errors && errors['manager']}
                        defaultValue={row.manager}
                        getOptions={api.getUsers}
                        onChange={option => setTimesheet(t => ({...t, manager: option ? option.value : null}))}
                    />}
                </div>
                <div className="col-4">
                    {row.is_closed && <div className='d-flex justify-content-start'>{row.project?.label || '-'}</div>}
                    {!row.is_closed && <Select
                        isAsync
                        isSearchable
                        isClearable
                        placeholder='Проект'
                        defaultValue={row.project}
                        isDisabled={!isManaged}
                        error={errors && errors['project']}
                        getOptions={api.getProjectsOptions}
                        onChange={option => setTimesheet(t => ({...t, project: option ? option.value : null}))}
                    />}
                </div>
                {isManaged && (
                    <TimesheetDayRowMenu
                        isClosed={isClosed}
                        onCloseTimesheet={closeTimesheet}
                        onoOpenTimesheet={openTimesheet}
                        onSplitTimesheet={splitTimesheet}
                    />
                )}
            </div>
            {row.children && row.children.map(item => (
                <TimesheetDayRow
                    key={item.id}
                    row={item}
                    onEditTimesheet={onEditTimesheet}
                    isChild
                />
            ))}
            {!isChild && <div className='timesheet-row--divider'/>}
        </>
    )
}

export default TimesheetDayRow;
