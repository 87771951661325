import React from 'react';
import {BiFilterAlt} from "react-icons/bi";


const FilterButton = ({onClick}) => (
  <span>
    <BiFilterAlt
      style={{fontSize: '20px', color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer'}}
      onClick={onClick}
    />
  </span>
);

export default React.memo(FilterButton);