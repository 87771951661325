import React, {memo} from 'react';

const BillsListTableHead = () => {
  return (
    <tr>
      <td>#</td>
      <td>Дата</td>
      <td>Контрагент</td>
      <td>Автор</td>
    </tr>
  );
};

export default memo(BillsListTableHead, () => true);