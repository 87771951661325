import React, {memo} from 'react';

const BillsListTableRow = ({bill, onBillSelect}) => {
  return (
    <>
      <tr onClick={onBillSelect}>
        <td>{bill.id}</td>
        <td>{bill.date}</td>
        <td>{bill.counterparty}</td>
        <td>{bill.author}</td>
      </tr>
    </>
  );
};

export default memo(BillsListTableRow);