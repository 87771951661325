import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import Loader from "../../Components/Loader";
import APIService from "../../services/api";
import TimesheetDayRow from './TimesheetDayRow';
import './TimesheetDay.css';


const TimesheetDay = () => {
    const params = useParams();
    const api = new APIService();

    const [timesheet, setTimesheet] = useState(null);

    useEffect(() => {
        getTimesheet();
    }, []);

    const getTimesheet = async () => {
        const _timesheet = await api.getTimesheet(params.date);
        setTimesheet(_timesheet);
    }

    if (timesheet === null) return <Loader/>;

    return (
        <div className='mt-4'>
            <div className="row d-flex justify-content-center mb-4"><b>{params.date}</b></div>
            {timesheet.map(item => (
                <TimesheetDayRow
                    key={item.id}
                    row={item}
                    onEditTimesheet={getTimesheet}
                />
            ))}
        </div>
    )
}

export default TimesheetDay;
