import React from "react";

import './AsidePanel.css';
import {Link} from "react-router-dom";

import AsidePanelTransactions from "./AsidePanelTransactions";

const AsidePanel = ({projects, toConfirm, toPay, myTransactions, timesheet}) => {
  const groupTransactions = (transactions) => {
    const grouped = {}
    transactions.forEach(t => {
      if (!grouped[t.manager]) {
        grouped[t.manager] = []
      }
      grouped[t.manager].push(t)
    })
    return grouped;
  }

  const groupedToConfirm = React.useMemo(() => {
    return groupTransactions(toConfirm);
  }, [toConfirm]);

  const groupedToPay = React.useMemo(() => {
    return groupTransactions(toPay);
  }, [toPay]);

  const groupedMyTransactions = React.useMemo(() => {
    return {'': myTransactions};
  }, [myTransactions]);

  const moveToTop = () => window.scrollTo(0, 0);

  return (
    <aside className='aside'>
      {timesheet && (
        <div className="aside-header">
          <Link to='/timesheet'><b>Табелювання</b></Link>
        </div>
      )}
      <div className='aside-header'>
        <Link to='/projects'><b>Мої проекти</b></Link>
      </div>
      {toConfirm.length > 0 && <div className='aside-header' onClick={moveToTop}>
        <AsidePanelTransactions
          headerText='На погодження'
          transactions={groupedToConfirm}
        />
      </div>}
      {toPay.length > 0 && <div className='aside-header' onClick={moveToTop}>
        <AsidePanelTransactions
          headerText='На оплату'
          transactions={groupedToPay}
        />
      </div>}
      {myTransactions.length > 0 && <div className='aside-header' onClick={moveToTop}>
        <AsidePanelTransactions
          headerText='Мої поточні платежі'
          transactions={groupedMyTransactions}
        />
      </div>}
    </aside>
  )
}

export default AsidePanel;