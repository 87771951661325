import React, {useState, useEffect, useReducer} from "react";

import {useNavigate, useParams} from "react-router-dom";

import Form from "../../Components/Form";
import Input from "../../Components/Input";
import Select from "../../Components/Select";
import APIService from "../../services/api";
import {useSnackbar} from "../../hooks";
import Loader from "../../Components/Loader";


const TimesheetWorkerChange = () => {
    const [worker, setWorker] = useState(null);
    const [workerState, dispatch] = useReducer(
        (state, newState) => ({...state, ...newState}),
        null,
    );
    const [errors, setErrors] = useState(null);

    const api = new APIService();
    const openSnackbar = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getWorker();
    }, []);

    useEffect(() => {
        worker && dispatch({
            name: worker.name,
            rate: worker.rate,
            status: worker.status,
        });
    }, [worker]);

    const getWorker = async () => {
        const _worker = await api.getWorker(params.workerId);
        setWorker((_worker));
    }

    const handleFormSubmit = async () => {
        try {
            await api.editWorker(params.workerId, {...workerState, status: +workerState.status.value});
            navigate(-1);
        } catch (e) {
            e.message ? openSnackbar(e.message) : setErrors(e.data);
        }
    }

    if (!workerState) return <Loader/>

    return (
        <div className='container'>
            <div className="row d-flex justify-content-center">
                <div className="col-8">
                    <Form
                        headerText='Редагування працівника'
                        submitButtonText='Зберегти'
                        onSubmit={handleFormSubmit}
                        error={errors && errors['non_field_errors']}
                    >
                        <div className="row">
                            <div className="col">
                                <Input
                                    value={workerState.name}
                                    type={'text'}
                                    placeholder='Прізвище та ініціали працівника'
                                    error={errors && errors['name']}
                                    onChange={e => dispatch({name: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Input
                                    value={workerState.rate}
                                    type={'text'}
                                    placeholder='Місячна ставка'
                                    error={errors && errors['rate']}
                                    onChange={e => dispatch({rate: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Select
                                    defaultValue={workerState.status}
                                    defaultOptions={[{value: 0, label: 'Працює'}, {value: 1, label: 'Звільнений'}]}
                                    placeholder='Статус'
                                    error={errors && errors['status']}
                                    onChange={option => dispatch({status: option})}
                                />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default TimesheetWorkerChange;