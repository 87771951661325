import React from 'react';
import Select from "../../Components/Select";
import APIService from "../../services/api";

const Filters = ({onUsersSelect, onCounterpartySelect}) => {
  const api = new APIService();

  return (
    <>
    <div className="col-6">
      <Select
        isSearchable
        isClearable
        isMulti
        getOptions={api.getUsers}
        placeholder='Користувачі'
        onChange={options => onUsersSelect(options.map(option => option.value))}
      />
    </div>
    <div className="col-6">
      <Select
        isSearchable
        isClearable
        isAsync
        getOptions={api.getCounterparties}
        placeholder='Контрагент'
        onChange={option => onCounterpartySelect(option ? option.value : null)}
      />
    </div>
  </>
  )
}

export default Filters;