import React from 'react';
import {Link} from "react-router-dom";

export const TransactionLink = ({transaction}) => {
  return (
    <div className='aside-row py-2'>
      <Link to={`/project/${transaction.project_id}/transaction/${transaction.id}`}>
        <span>&#x2022; {transaction.id} - {transaction.name}</span>
      </Link>
    </div>
  );
};
export default TransactionLink;
