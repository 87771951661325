import React from 'react';

import BillsListTable from "./BillsListTable";
import Filters from "./Filters";
import Button from "../../Components/Button";
import APIService from "../../services/api";

const ProjectBillsList = () => {
  const [bills, setBills] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [users, setUsers] = React.useState([]);
  const [counterparty, setCounterparty] = React.useState(null);
  const api = new APIService();

  React.useEffect(() => {
    setPage(1);
    getBills();
  }, [users, counterparty]);

  const getBills = () => {
    api.getBills(1, users, counterparty)
      .then((data) => {
        setBills(data.results);
        setPage(prev => data.next ? page + 1 : null);
      });
  };

  const getNextPageBills = (page) => {
    setPage(null);
    api.getBills(page, users, counterparty)
      .then((data) => {
        setBills(prev => prev ? [...prev, ...data.results] : data.results);
        setPage(prev => data.next ? page + 1 : null);
      });
  };

  if (!bills) return null;

  return (
    <>
      <div class="row pt-4">
        <Filters
          onUsersSelect={setUsers}
          onCounterpartySelect={setCounterparty}
        />
      </div>
      <div class='row'>
        <div class="col">
          <BillsListTable bills={bills}/>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-2 m-4">
          <Button
            text='Завантажити ще'
            disabled={!page}
            onClick={() => getNextPageBills(page)}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectBillsList;
