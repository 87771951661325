import React, {memo} from 'react';

const ProjectTransactionsEmptyTable = () => {
    return (
        <tr>
            <td colSpan={8}><b style={{textAlign: 'center'}}>Транзакції відсутні.</b></td>
        </tr>
    );
};

export default memo(ProjectTransactionsEmptyTable, () => true);