import React from "react";

import {Route, Routes} from "react-router-dom";
import SnackbarProvider from 'react-simple-snackbar';

import './App.css';

import HomePage from './Pages/Home';
import ProjectsList from './Pages/ProjectsList';
import ProjectAdd from './Pages/ProjectAdd';
import ProjectEdit from './Pages/ProjectEdit';
import Project from './Pages/Project';
import ProjectHistory from './Pages/ProjectHistory';
import ProjectMembers from './Pages/ProjectMembers';
import ProjectTransaction from './Pages/ProjectTransaction';
import ProjectTransactions from './Pages/ProjectTransactions';
import ProjectTransactionAdd from './Pages/ProjectTransactionAdd';
import ProjectBillsList from './Pages/ProjectBillsList';
import ProjectTransactionSkladLink from './Pages/ProjectTransactionSkladLink';
import TimesheetCalendar from './Pages/TimesheetCalendar';
import TimesheetDay from './Pages/TimesheetDay';
import TimesheetWorkerChange from './Pages/TimesheetWorkerChange';
import AuthPage from './Pages/Auth';


const App = () => {
  return (
    <div className="App">
      <SnackbarProvider>
        <Routes>
          <Route path="/" element={<HomePage/>}>
            <Route path="/projects" element={<ProjectsList/>}/>
            <Route path="/projects/bills" element={<ProjectBillsList/>}/>
            <Route path="/project/add" element={<ProjectAdd/>}/>
            <Route path="/project/:projectId" element={<Project/>}>
              <Route path="/project/:projectId/edit" element={<ProjectEdit/>}/>
              <Route path="/project/:projectId/subprojects" element={<ProjectsList/>}/>
              <Route path="/project/:projectId/history" element={<ProjectHistory/>}/>
              <Route path="/project/:projectId/members" element={<ProjectMembers/>}/>
              <Route path="/project/:projectId/transactions" element={<ProjectTransactions/>}/>
              <Route path="/project/:projectId/transactions/add" element={<ProjectTransactionAdd/>}/>
              <Route path="/project/:projectId/transaction/:transactionId" element={<ProjectTransaction/>}/>
            </Route>
            <Route path="/sklad/link/" element={<ProjectTransactionSkladLink/>}/>
            <Route path='/timesheet' element={<TimesheetCalendar/>}/>
            <Route path='/timesheet/:date' element={<TimesheetDay/>}/>
            <Route path='/timesheet/worker/:workerId' element={<TimesheetWorkerChange/>}/>
          </Route>
          <Route path="/auth" element={<AuthPage/>}/>
        </Routes>
      </SnackbarProvider>
    </div>
  )
}

export default App;
