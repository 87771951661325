import React from 'react';

import RefreshButton from "./RefreshButton";
import FilterButton from "./FilterButton";
import UserSelect from "./UserSelect";
import TransactionLink from "./TransactionLink";
import {UserContext} from "../../context";


const AsidePanelTransactions = ({headerText, transactions}) => {
  const [showFilter, setShowFilter] = React.useState(false);
  const [filteredUser, setFilteredUser] = React.useState(null);

  const {getCurrentUser} = React.useContext(UserContext);

  const filteredTransactions = React.useMemo(() => {
    if (!filteredUser || !showFilter) return transactions;
    return {[filteredUser.label]: transactions[filteredUser.label] || []}
  }, [filteredUser, transactions, showFilter]);

  const toggleFilter = () => setShowFilter(prev => !prev);

  return (
    <>
      <div className='row justify-content-between'>
        <div className="col-8">
          <h4><b>{headerText}</b></h4>
        </div>
        <div className="col d-flex justify-content-end">
          <RefreshButton onClick={getCurrentUser} />
          <FilterButton onClick={toggleFilter} />
        </div>
      </div>
      {showFilter && (
        <div className="row">
          <div className="col">
            <UserSelect
              user={filteredUser}
              onChange={setFilteredUser}
            />
          </div>
        </div>
      )}
      {Object.keys(filteredTransactions).map(manager => (
        <React.Fragment key={manager}>
          {manager && <h4 className='aside-header--manager'>- {manager}</h4>}
          {filteredTransactions[manager].map(t => (
            <TransactionLink key={t.id} transaction={t} />
          ))}
        </React.Fragment>
      ))}
    </>
  )
}

export default AsidePanelTransactions;