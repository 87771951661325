import React, {useState} from "react";

import Form from "../../Components/Form";
import Select from "../../Components/Select";
import APIService from "../../services/api";

const CloseTransactionModalWindow = ({onSubmit}) => {
  const [timCounterparty, setTimCounterparty] = useState(null);

  const api = new APIService();

  return (
    <Form
      headerText='Закриття транзакції'
      submitButtonText='Закрити'
      onSubmit={() => onSubmit(timCounterparty || null)}
    >
      <div className="row">
        <div className="col">
          <Select
            isAsync
            isClearable
            isSearchable
            placeholder='Покупець'
            getOptions={api.getTIMCounterparties}
            onChange={option => setTimCounterparty(option ? option.value : null)}
          />
        </div>
      </div>
    </Form>
  )
}

export default CloseTransactionModalWindow;