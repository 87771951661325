import React, {useState} from 'react';

import {useSnackbar} from "../../../hooks";

import AttachmentsList from "./AttachmentsList";
import AddAttachment from "./AddAttachment";

import './Attachments.css';
import APIService from "../../../services/api";

const Attachments = ({ transactionId, isReadOnly = false, attachmentItems }) => {
  const [attachments, setAttachments] = useState(() => attachmentItems);

  const openSnackbar = useSnackbar();

  const addAttachment = (file) => {
    if (isReadOnly) return;
    const api = new APIService();
    return api.uploadFile(file)
      .then(res => res.file_id)
      .then(fileId => api.addTransactionAttachment(transactionId, fileId))
      .then(attachment => setAttachments([...attachments, attachment]))
      .catch(e => openSnackbar(e.message));
  };

  const deleteAttachment = (attachmentId) => {
    if (isReadOnly) return;
    if (!window.confirm('Підтвердіть видалення вкладення.')) return;

    const api = new APIService();
    return api.deleteTransactionAttachment(transactionId, attachmentId)
      .then(() => setAttachments(attachments.filter(attachment => attachment.id !== attachmentId)))
      .catch(e => openSnackbar(e.message));
  };

  return (
    <>
      <AttachmentsList
        isReadOnly={isReadOnly}
        attachments={attachments}
        onAttachmentDelete={deleteAttachment}
      />
      {!isReadOnly && <AddAttachment onAddAttachment={addAttachment}/> }
      <hr/>
    </>
  );
};

export default Attachments;