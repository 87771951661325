import React, {memo} from "react";

import Drawer from 'react-drag-drawer';

import './ModalWindow.css';

const ModalWindow = ({children, showModal, toggleModal}) => {
    return (
        <Drawer
            open={showModal}
            onRequestClose={toggleModal}
            modalElementClass={'modal'}
        >
            <div>
                {children}
            </div>
        </Drawer>
    )
}

const propsAreEqual = (prev, next) => {
    return prev.showModal === next.showModal && prev.toggleModal === next.toggleModal;
}

export default memo(ModalWindow, propsAreEqual);