import React, {memo} from 'react';
import Switch from "../../Components/Switch";
import {TiDelete} from "react-icons/ti";

const ProjectMemberTableRow = ({member, onUpdateProjectMemberRole, onRemoveProjectMember}) => {
    return (
        <tr>
            <td>{member.name}</td>
            <td style={{width: '36px'}}>
                <Switch
                    checked={member.is_manager}
                    onChange={onUpdateProjectMemberRole}
                />
            </td>
            <td style={{textAlign: 'right'}}>
                <TiDelete
                    style={{fontSize: '48px', color: '#FF8D70'}}
                    onClick={onRemoveProjectMember}
                />
            </td>
        </tr>
    );
};

const propsAreEqual = (prev, next) => {
    return prev.member.is_manager === next.member.is_manager;
}

export default memo(ProjectMemberTableRow, propsAreEqual);