import React, {useState, useEffect, useMemo, memo} from "react";

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import ErrorText from "../ErrorText";

import './Select.css';

const SelectInput = (
  {
    defaultOptions,
    getOptions,
    onInputChange,
    defaultValue,
    placeholder,
    onChange,
    error,
    isDisabled = false,
    isAsync = false,
    isCreatable = false,
    isClearable = false,
    isSearchable = false,
    isMulti = false,
  }
) => {
  const [options, setOptions] = useState(defaultOptions || null);
  const [isFocused, setIsFocused] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    if (options) return;
    if (isAsync) return setOptions([]);
    (async () => setOptions(await getOptions()))();
  }, []);

  const getOptionsAsync = async (inputValue = '', callback) => {
    callback && callback(await getOptions(inputValue));
  }

  let SelectComponent = useMemo(() => {
    if (isAsync) {
      return isCreatable ? AsyncCreatableSelect : AsyncSelect;
    }
    return isCreatable ? CreatableSelect : Select;
  }, [isAsync, isCreatable]);

  const onSelect = (option) => {
    onChange(option);
    if (Array.isArray(option)) {
      setIsEmpty(option.length === 0);
    } else {
      setIsEmpty(option ? !option.value : true);
    }
  }
  if (!options) return null;
  return (
    <>
      <SelectComponent
        defaultValue={defaultValue}
        options={options || []}
        placeholder=''
        styles={customStyles}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isMulti={isMulti}
        isLoading={options == null}
        noOptionsMessage={() => 'Список порожній'}
        formatCreateLabel={inputValue => `Створити "${inputValue}"`}
        formNoValidate={error}
        onFocus={e => setIsFocused(true)}
        onBlur={e => setIsFocused(false)}
        onInputChange={e => onInputChange && onInputChange(e)}
        onChange={onSelect}
        loadOptions={getOptionsAsync}
        isDisabled={isDisabled}
        blurInputOnSelect
      />
      <span
        className={`select-floating-label ${defaultValue || isFocused || !isEmpty ? 'small' : 'big'}`}>{placeholder}</span>
      <ErrorText text={error}/>
    </>
  )
}

const propsAreEqual = (prev, next) => {
  return prev.isLoading === next.isLoading
    && prev.defaultOptions === next.defaultOptions
    && prev.defaultValue === next.defaultValue
    && prev.isDisabled === next.isDisabled
    && prev.error === next.error;
};

export default memo(SelectInput, propsAreEqual);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.formNoValidate ? 'rgba(255, 63, 46, 0.12)' : '#EEF3F5',
    height: '56px',
    marginBottom: state.selectProps.formNoValidate ? '2px' : '16px',
    borderRadius: '12px',
    padding: '8px',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: 'none',
  }),
  container: (provided, state) => ({
    ...provided,
    borderColor: '#EEF3F5',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    textAlign: 'left',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#EEF3F5' : 'white',
    padding: '16px',
    color: 'black',
    textAlign: 'left',
    fontWeight: state.isSelected ? '700' : '400',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    textAlign: 'left',
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    backgroundColor: '#EDF4F7',
    color: 'black',
    borderColor: '#EEF3F5',
    paddingRight: 6,
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    backgroundColor: '#EEF3F5',
    "&:hover": {
      backgroundColor: '#EDF4F7',
      color: "black"
    }
  }),
}