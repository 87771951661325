import React, {memo} from "react";

import {Link} from 'react-router-dom';

import './Header.css';

const Header = ({onLogoutButtonPress}) => (
    <header className='row justify-content-between header'>
        <div className="col-6 col-lg-2">
            <Link to='/projects'><span className='header--logo'>moneypot</span></Link>
        </div>
        <div className="col-6 col-lg-2 d-flex justify-content-end">
            <button
                className='header--logout'
                onClick={onLogoutButtonPress}
            >Вихід
            </button>
        </div>
    </header>
);

export default memo(Header, () => true);