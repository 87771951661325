import React, {useEffect, useState} from "react";

import {useParams} from "react-router-dom";

import Select from '../../Components/Select';
import Button from '../../Components/Button';
import Loader from '../../Components/Loader';
import Table from '../../Components/Table';
import ProjectMemberTableRow from './ProjectMemberTableRow';
import APIService from "../../services/api";
import {useSnackbar} from "../../hooks";

const ProjectMembers = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [members, setMembers] = useState(null);
  const [errors, setErrors] = useState(null);

  const urlParams = useParams();

  const api = new APIService();
  const openSnackbar = useSnackbar();

  useEffect(() => {
    getProjectMembers();
  }, []);

  useEffect(() => setErrors(null), [selectedUser, members])

  const getProjectMembers = async () => setMembers(await api.getProjectMembers(urlParams.projectId));

  const addProjectMember = async () => {
    if (!selectedUser) return;

    const isUser = selectedUser.type === 'user';

    const addProjectMemberFunc = isUser ? api.addProjectMember : api.addProjectMemberGroup;
    let member = null;
    try {
      member = await addProjectMemberFunc(urlParams.projectId, selectedUser.value);
    } catch (e) {
      return openSnackbar(e.message);
    }

    member && setMembers([...members, member]);
  }

  const removeProjectMember = async (userId) => {
    if (!window.confirm('Ви дійсно бажаєте видалити учасника проекту?')) return;

    try {
      await api.removeProjectMember(urlParams.projectId, userId);
    } catch (e) {
      return openSnackbar(e.message);
    }

    setMembers(members.filter(m => m.user !== parseInt(userId)));
  }

  const updateProjectMemberRole = async (userId, isManager) => {
    let member;
    try {
      member = await api.updateProjectMember(urlParams.projectId, {user_id: userId, is_manager: isManager});
    } catch (e) {
      return openSnackbar(e.message);
    }
    setMembers(state => state.map(m => {
      if (m.user === userId) {
        return member;
      }
      return m;
    }))
  }

  if (!members) return <Loader/>;

  return (
    <div className='container'>
      <div className="row d-flex justify-content-center mb-3">
        <div className="col-12 col-lg-6">
          <Select
            isSearchable
            isClearable
            getOptions={api.getUsers}
            placeholder='Користувач'
            error={errors}
            onChange={option => setSelectedUser(option || null)}
          />
        </div>
        <div className="col-12 col-lg-3">
          <Button
            disabled={!selectedUser}
            text='Додати'
            onClick={addProjectMember}
          />
        </div>
      </div>

      <div className="row d-flex justify-content-center">
        <div className="col-12 col-lg-9">
          <Table
            rows={members}
            renderHead={() => (
              <tr>
                <td>Користувач</td>
                <td>Менеджер</td>
                <td>Видалити</td>
              </tr>
            )}
            renderRow={member => (
              <ProjectMemberTableRow
                member={member}
                onUpdateProjectMemberRole={isChecked => updateProjectMemberRole(member.user, isChecked)}
                onRemoveProjectMember={() => removeProjectMember(member.user)}
              />
            )}
          />
          {/*<Table*/}
          {/*    rows={memberGroups}*/}
          {/*    renderRow={group => (*/}
          {/*        <ProjectMemberTableRow*/}
          {/*            member={group}*/}
          {/*            onRemoveProjectMember={() => removeProjectMemberGroup(group.group)}*/}
          {/*        />*/}
          {/*    )}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  )
}

export default ProjectMembers;
