import React from 'react';

import {useNavigate} from "react-router-dom";

import Table from "../../../Components/Table";
import BillsListTableHead from './BillsListTableHead';
import BillsListTableRow from './BillsListTableRow';
import BillsListEmptyTable from './BillsListEmptyTable';


const BillsListTable = ({bills}) => {
  const navigate = useNavigate();

  const navigateToBillTransaction = (transaction) => navigate(`/project/${transaction.projectId}/transaction/${transaction.id}`);
  return (
    <Table
      rows={bills}
      renderHead={() => <BillsListTableHead/>}
      renderRow={bill => (
        <BillsListTableRow
          key={bill.id}
          bill={bill}
          onProjectSelect={() => navigateToBillTransaction(bill.transaction)}
        />
      )}
      renderEmpty={() => <BillsListEmptyTable/>}
    />
  );
};

export default BillsListTable;