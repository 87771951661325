import React, {memo} from 'react';

const TotalAmountBadge = ({totalAmount, sellingTotalAmount, diff = null, currency}) => (
    <div className='d-flex flex-column align-items-end justify-content-center'>
      <div><b className='total-amount'>{totalAmount} {currency}</b></div>
      {sellingTotalAmount && <div><b className='total-selling-amount'>{sellingTotalAmount} {currency}</b></div>}
      {sellingTotalAmount && diff && <div><b className='total-selling-amount'>{diff} {currency}</b></div>}
    </div>
)

const propsAreEqual = (prev, next) => prev.totalAmount === next.totalAmount &&
  prev.sellingTotalAmount === next.sellingTotalAmount;

export default memo(TotalAmountBadge, propsAreEqual);