import React from 'react';

const ProjectListEmptyTable = () => {
    return (
        <tr>
            <td colSpan={7}>
                <b>Ви не є учасником жодного проекту.</b>
            </td>
        </tr>
    );
};

export default ProjectListEmptyTable;