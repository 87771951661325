import React from 'react';

import './Dropdown.css';
import button from "../Button";

const Dropdown = ({text, children}) => {
  const [showMenu, setShowMenu] = React.useState(false);
  return (
    <div className="dropdown">
      <button
        className='dropdown__button'
        onClick={() => setShowMenu(prev => !prev)}
      >{text}</button>
      {showMenu && (
        <div className='dropdown__menu' onClick={() => setShowMenu(false)}>
          {children}
        </div>
      )}
    </div>
  );
}

const DropdownItem = ({text, onClick}) => (
  <button className='dropdown__item' onClick={onClick}>{text}</button>
)

export {
  Dropdown,
  DropdownItem,
};