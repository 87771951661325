import React, {memo, useEffect} from 'react';

import Input from "../../../../Components/Input";
import TotalAmountBadge from "../TotalAmountBadge";
import RemoveRowButton from "./RemoveRowButton";

const IncomeTransactionItemFormRow = ({item, errors, currency, updateRow, onRowDelete}) => {
    useEffect(() => {
        updateRow('quantity', '1');
        updateRow('selling_amount', '0');
    }, []);

    return (
        <div className="row transaction-form-row justify-content-center">
            <div className="col-6 col-lg-6 p-0 pr-2">
                <Input
                    type='text'
                    placeholder='Назва'
                    value={item.name}
                    error={errors && errors['name']}
                    onChange={e => updateRow('name', e.target.value)}
                />
            </div>
            <div className="col-6 col-lg-3 p-0">
                <Input
                    type='number'
                    placeholder={`Сума, ${currency}`}
                    value={item.amount}
                    error={errors && errors['amount']}
                    onChange={e => updateRow('amount', e.target.value)}
                />
            </div>
            <div className="col-12 col-lg-3 d-flex justify-content-end align-items-baseline">
                <TotalAmountBadge
                    totalAmount={item.total_amount}
                    sellingTotalAmount={null}
                    currency={currency}
                />
                <RemoveRowButton onClick={() => onRowDelete(item.id)}/>
            </div>
        </div>

    )
}

const propsAreEqual = (prev, next) => {
    return prev.item.name === next.item.name
        && prev.item.amount === next.item.amount
        && prev.item.total_amount === next.item.total_amount
        && prev.errors === next.errors;
};

export default memo(IncomeTransactionItemFormRow, propsAreEqual);