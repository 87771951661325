import React, {memo} from 'react';

import ProjectListTreeView from "../ProjectListTreeView";
import ToggleButton from "../../../Components/ToggleButton";

const ProjectListTableRow = ({project, showChildrenGlobal, onProjectSelect}) => {
  const [showChildren, setShowChildren] = React.useState(() => {
    const localShowChildren = localStorage.getItem(`project-${project.id}-show-children`);
    if (localShowChildren === null) return false;
    return localShowChildren === '1';
  });

  const onToggleShowChildren = (e) => {
    e.stopPropagation();
    setShowChildren(state => {
      const toShow = !state;
      if (toShow) {
        localStorage.setItem(`project-${project.id}-show-children`, '1');
      } else {
        localStorage.removeItem(`project-${project.id}-show-children`);
      }
      return toShow;
    });
  }

  const isRootProject = project.level === 0;
  return (
    <>
      <tr onClick={() => onProjectSelect(project.id)} className={`${isRootProject ? 'main-project' : ''}`}>
        <td style={{width: '48px'}}>
          {isRootProject && project.children.length > 0 && (
            <ToggleButton
              show={showChildren}
              onClick={onToggleShowChildren}
            />
          )}
        </td>
        <td>{project.id}</td>
        <td>
          <ProjectListTreeView
            level={project.level}
            projectName={project.name}
          />
        </td>

        <td className={`${+project.balance < 0 ? 'table-negative-value' : 'table-positive-value'}`}>
          {project.balance === null ? '-' : `${project.currency} ${project.balance}`}
        </td>
        <td>{project.counterparty.label}</td>
        <td>{project.owner}</td>
        <td>{project.created_at}</td>
      </tr>
      {(showChildren || showChildrenGlobal || !isRootProject) && project.children.map(childProject => (
        <ProjectListTableRow
          key={childProject.id}
          project={childProject}
          onProjectSelect={onProjectSelect}
        />
      ))}
    </>
  );
};

export default memo(ProjectListTableRow);