import React from 'react';
import Button from "../../Components/Button";
import {Dropdown, DropdownItem} from "../../Components/Dropdown";

const ProjectTransactionMenuRow = (
  {
    onCopyTransactionButtonPress,
    onShowSkladImportModalButtonPress,
    onReOpenTransactionButtonPress,
    onConfirmTransactionButtonPress,
    onCloseTransactionButtonPress,
    onExportTransactionToSkladButtonPress,
  }) => {
  return (
    <div className="row d-flex justify-content-end mb-4">
      <div className='col-12 col-sm-1 mb-2'>
        <Dropdown text='Інше'>
          {onCopyTransactionButtonPress && <DropdownItem text='Копіювати' onClick={onCopyTransactionButtonPress} />}
          {onShowSkladImportModalButtonPress && <DropdownItem text='Імпорт зі складу' onClick={onShowSkladImportModalButtonPress} />}
          {onExportTransactionToSkladButtonPress && <DropdownItem text='Експорт у склад' onClick={onExportTransactionToSkladButtonPress} />}
        </Dropdown>
      </div>
      {onConfirmTransactionButtonPress && (
        <div className="col-12 col-md-2 mb-2">
          <Button
            variant='danger small'
            text='Погодити'
            disabled={false}
            onClick={onConfirmTransactionButtonPress}
          />
        </div>
      )}
      {onReOpenTransactionButtonPress && (
        <div className='col-12 col-md-2 mb-2'>
          <Button
            text='Відкрити повторно'
            disabled={false}
            variant={'success small'}
            onClick={onReOpenTransactionButtonPress}
          />
        </div>
      )}
      {onCloseTransactionButtonPress && (
        <div className='col-12 col-md-2 mb-2'>
          <Button
            text='Закрити'
            disabled={false}
            variant={'danger small'}
            onClick={onCloseTransactionButtonPress}
          />
        </div>
      )}
    </div>
  )
    ;
}

export default ProjectTransactionMenuRow;