import React, {memo} from "react";

import {TiDeleteOutline} from "react-icons/ti";

const RemoveRowButton = ({onClick}) => (
    <div className='d-flex align-items-center justify-content-center'>
      <b>
        <TiDeleteOutline
            style={{color: '#FF9D85', fontSize: '48px', cursor: 'pointer', paddingLeft: '16px'}}
            onClick={onClick}
        />
    </b>
    </div>
)

export default memo(RemoveRowButton, () => true);
