import React from 'react';

import {useNavigate} from "react-router-dom";

import Table from "../../../Components/Table";
import ProjectListTableHead from './ProjectListTableHead';
import ProjectListTableRow from './ProjectListTableRow';
import ProjectListEmptyTable from './ProjectListEmptyTable';


const ProjectListTable = ({projects, showChildren}) => {
    const navigate = useNavigate();

    const navigateToProject = (projectId) => navigate(`/project/${projectId}/transactions`);
    return (
        <Table
            rows={projects}
            renderHead={() => <ProjectListTableHead/>}
            renderRow={project => (
                <ProjectListTableRow
                    key={project.id}
                    project={project}
                    showChildrenGlobal={showChildren}
                    onProjectSelect={navigateToProject}
                />
            )}
            renderEmpty={() => <ProjectListEmptyTable/>}
        />
    );
};

export default ProjectListTable;