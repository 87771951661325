import React, {useContext, useState} from 'react';

import {useNavigate, useParams} from "react-router-dom";

import Table from "../../../Components/Table";
import ProjectTransactionsTableHead from './ProjectTransactionsTableHead';
import ProjectTransactionsTableRow from './ProjectTransactionsTableRow';
import ProjectTransactionsEmptyTable from './ProjectTransactionsEmptyTable';
import {ProjectContext} from "../../../context";


const ProjectTransactionsTable = ({transactions, isPurchase}) => {
    const [showDetails, setShowDetails] = useState(false);

    const urlParams = useParams();
    const navigate = useNavigate();

    const {project} = useContext(ProjectContext);

    const navigateToTransaction = (transactionId) => {
        navigate(`/project/${urlParams.projectId}/transaction/${transactionId}`);
    }
    return (
        <Table
            rows={transactions}
            renderHead={() => (
                <ProjectTransactionsTableHead
                    showDetails={showDetails}
                    isPurchase={project.is_purchase}
                    toggleShowDetails={() => setShowDetails(state => !state)}
                />
            )}
            renderRow={transaction => (
                <ProjectTransactionsTableRow
                    key={transaction.id}
                    transaction={transaction}
                    showDetailsGlobal={showDetails}
                    isPurchase={project.is_purchase}
                    onSelect={() => navigateToTransaction(transaction.id)}
                />
            )}
            renderEmpty={() => <ProjectTransactionsEmptyTable />}
        />
    );
};

export default ProjectTransactionsTable;
