import React from 'react';
import {TiDeleteOutline} from "react-icons/ti";

const AttachmentItem = ({ attachment, isReadOnly, onAttachmentDelete }) => {
  return (
    <div className="row attachment-item">
      <div className='col-5 attachment-item__url'>
        <a href={attachment.url}
           target="_blank"
           rel="noopener noreferrer"
        >
          {attachment.name}
        </a>
      </div>
      <div className="col-3">
        <span className='attachment-item__text'>{attachment.uploader}</span>
      </div>
      <div className="col-3">
        <span className='attachment-item__text'>{attachment.created_at}</span>
      </div>
      {!isReadOnly && (
        <div className="col">
          <div className='d-flex align-items-center justify-content-end'>
            <b className='attachment-item__delete justify-content-end'>
              <TiDeleteOutline onClick={() => onAttachmentDelete(attachment.id)}/>
            </b>
          </div>
        </div>
      )}
    </div>
  );
};

export default AttachmentItem;
