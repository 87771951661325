import React, {useMemo} from "react";

import Loader from '../../../Components/Loader';
import IncomeReadOnlyTable from "./IncomeReadOnlyTable";
import OutcomeReadOnlyTable from "./OutcomeReadOnlyTable";
import TransactionTableHeader from "./TransactionTableHeader";
import Bill from "./Bill";
import Attachments from '../Attachments';

const TransactionReadOnlyTable = ({transaction, transactionItems, bill, isOutcome}) => {
  const TableComponent = useMemo(() => {
    return isOutcome ? OutcomeReadOnlyTable : IncomeReadOnlyTable;
  }, [isOutcome]);

  return (
    <div className={`${isOutcome ? 'container-fluid' : 'container'}`}>
      <TransactionTableHeader
        transaction={transaction}
        isOutcome={isOutcome}
      />
      <hr/>
      {bill && isOutcome && (
        <Bill bill={bill} />
      )}
      <Attachments
        isReadOnly
        transactionId={transaction.id}
        attachmentItems={transaction.attachments}
      />
      {!transactionItems ? <Loader/> : <TableComponent
        transactionItems={transactionItems}
        transactionAmount={transaction.amount}
        transactionSellingAmount={isOutcome ? transaction.selling_amount : null}
      />}
    </div>
  )
}

export default TransactionReadOnlyTable;
