import React, {useState, memo} from 'react';
import ErrorText from "../ErrorText";

import './Textarea.css';

const Textarea = ({value, placeholder, error, onChange, rows = 5}) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <div className='d-flex flex-column justify-content-start'>
            <div>
            <textarea
                className={`textarea ${error ? 'textarea--error' : ''}`}
                value={value}
                rows={rows}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
                <span className={`textarea-floating-label ${value || isFocused ? 'small' : 'big'}`}>{placeholder}</span>
            </div>
            {error && <ErrorText text={error}/>}
        </div>
    )
};

const propsAreEqual = (prevProps, nextProps) => prevProps.value === nextProps.value && prevProps.error === nextProps.error;

export default memo(Textarea, propsAreEqual);