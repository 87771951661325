import React, {useState} from "react";

import {useNavigate} from "react-router-dom";

import Form from "../../Components/Form";
import Select from "../../Components/Select";
import APIService from "../../services/api";
import {useSnackbar} from "../../hooks";

const ProjectTransactionCopy = ({transactionId}) => {
  const [projectTo, setProjectTo] = useState(null);

  const navigate = useNavigate();
  const api = new APIService();
  const openSnackbar = useSnackbar();

  const handleFormSubmit = async () => {
    const projectData = {
      project_to_id: projectTo,
    }

    api.copyTransaction(transactionId, projectData)
      .then((transaction) => navigate(`/project/${transaction.project}/transaction/${transaction.id}`))
      .catch((e) => openSnackbar(e.message));
  }

  return (
    <Form
      headerText='Копіювати транзакцію'
      submitButtonText='Скопіювати'
      onSubmit={handleFormSubmit}
    >
      <div className="row">
        <div className="col">
          <Select
            isAsync
            isSearchable
            placeholder='Проект'
            getOptions={api.getProjectsOptions}
            onChange={option => setProjectTo(option ? option.value : null)}
          />
        </div>
      </div>
    </Form>
  )
}

export default ProjectTransactionCopy;