import React, {useState} from "react";

import {useNavigate} from "react-router-dom";

import Form from '../../Components/Form';
import Input from '../../Components/Input';
import APIService from "../../services/api";

import './Auth.css';

const AuthPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(null);

  const navigate = useNavigate();

  const api = new APIService();

  async function authenticate() {
    api.authenticate({username, password})
      .then(isAuthenticated => isAuthenticated && navigate('/', {replace: true}))
      .catch(e => e.data && setErrors(e.data));
  }

  return (
    <div className='container auth-page'>
      <h1>moneypot</h1>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-5">
          <Form
            headerText='Вхід'
            submitButtonText='Ввійти'
            error={errors && errors['non_field_errors']}
            onSubmit={authenticate}
          >
            <div className="row">
              <div className="col">
                <Input
                  value={username}
                  type={'text'}
                  placeholder='Ваш логін'
                  error={errors && errors['username']}
                  onChange={e => setUsername(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Input
                  value={password}
                  type='password'
                  placeholder='Пароль'
                  error={errors && errors['password']}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default AuthPage;
