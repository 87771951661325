import React from 'react';
import {BiRefresh} from "react-icons/bi";


const RefreshButton = ({onClick}) => (
  <span title='Оновити' className='mr-1'>
    <BiRefresh
      style={{fontSize: '26px', color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer'}}
      onClick={onClick}
    />
  </span>
);

export default React.memo(RefreshButton);
