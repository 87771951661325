import React from 'react';

const TransactionTable = ({transaction}) => {
  if (!transaction) {
    return <p>Оберіть транзакцію</p>;
  }
  return (
    <>
      <div style={{borderBottom: '1px solid rgba(0, 0, 0, 0.2)', width: '100%'}}/>
      <div className="d-flex flex-column align-items-start">
        <h3 className='pt-2 pb-3'>Товари</h3>
        {transaction.items.map((item) => (
          <div key={item.id}>
            <p><b>{item.goods ? item.goods.label : item.name}</b> - {item.quantity} шт. * {item.amount} грн.</p>
          </div>
        ))}
      </div>
      <div style={{borderBottom: '1px solid rgba(0, 0, 0, 0.2)', width: '100%'}}/>
      <div className='d-flex flex-column align-items-start pt-2'>
        <p><b>Всього:</b> {transaction.amount} грн.</p>
      </div>
    </>
  )
}

export default React.memo(TransactionTable);
