import React, {useState, useEffect} from "react";

import {Outlet, Link, useParams, useLocation} from "react-router-dom";

import Button from "../../Components/Button";
import Breadcrumbs from "../../Components/Breadcrumbs";
import ModalWindow from "../../Components/ModalWindow";
import ProjectTransactionAdd from "../ProjectTransactionAdd";
import APIService from "../../services/api";
import {ProjectContext} from "../../context";
import {useSnackbar} from "../../hooks";

import './Project.css';

const Project = () => {
    const [project, setProject] = useState(null);
    const [showAddTransactionModal, setShowAddTransactionModal] = useState(false);
    const [transactionTypeAdd, setTransactionTypeAdd] = useState(1);
    const params = useParams();
    const location = useLocation();
    const openSnackbar = useSnackbar();
    const api = new APIService();

    useEffect(() => window.scroll(0,0), []);

    useEffect(() => {
        getProject(params.projectId);
        return setProject(null);
    }, [params.projectId, params.transactionId]);

    const getProject = async (projectId) => {
        try {
            setProject(await api.getProject(projectId));
        } catch (e) {
            openSnackbar(e.message);
        }
    }

    const addOutcomeTransaction = async () => {
        await setTransactionTypeAdd(1);
        setShowAddTransactionModal(true);
    }

    const addIncomeTransaction = async () => {
        await setTransactionTypeAdd(2);
        setShowAddTransactionModal(true);
    }

    const tabs = [
        {url: `/project/${params.projectId}/transactions`, name: 'Платежі'},
        {url: `/project/${params.projectId}/subprojects`, name: 'Підпроекти'},
        {url: `/project/${params.projectId}/members`, name: 'Учасники'},
        {url: `/project/${params.projectId}/history`, name: 'Історія'},
        {url: `/project/${params.projectId}/edit`, name: 'Налаштування'},
    ]

    if (!project) return null;

    return (
        <ProjectContext.Provider value={{project, setProject, getProject}}>
            <ModalWindow
                showModal={showAddTransactionModal}
                toggleModal={() => setShowAddTransactionModal(!showAddTransactionModal)}
            >
                <ProjectTransactionAdd
                    type={transactionTypeAdd}
                    onTransactionAdd={() => setShowAddTransactionModal(false)}
                />
            </ModalWindow>
            <div className="row">
                <Breadcrumbs>
                    <Link to='/projects'>Проекти</Link>
                    <span>›</span>
                    {project.ancestors.map(a => (
                      <>
                          <Link to={`/project/${a.id}/subprojects`}>{a.name}</Link>
                          <span>›</span>
                      </>
                    ))}
                    <Link to={`/project/${params.projectId}/transactions`}>{project.name}</Link>
                </Breadcrumbs>
            </div>
            <div className="row d-flex justify-content-between align-items-center project">
                <div className="col-12 col-lg-3 d-flex flex-column justify-content-start project-header">
                    <h1>{project.currency} {project.balance || '-'} </h1>
                    <h4><b>{project.dirty_balance} {project.currency}</b> після закриття всіх транзакцій.</h4>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-center project-tabs-menu flex-wrap">
                    {tabs.map(t => (
                        <Link
                            key={t.name}
                            className={`${t.url === location.pathname ? 'active' : ''}`}
                            to={t.url}
                        >{t.name}</Link>
                    ))}
                </div>
                <div className="col-12 col-lg-3">
                    {(`/project/${params.projectId}/transactions` === location.pathname && project.status === 0) && (
                        <div className="row">
                            <div className="col">
                                <Button
                                    text='- Витрата'
                                    variant='danger'
                                    onClick={addOutcomeTransaction}
                                />
                            </div>
                            <div className="col">
                                <Button
                                    text={project.is_pot ? '+ Поповнення' : '+ Дохід'}
                                    onClick={addIncomeTransaction}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <>
                <Outlet/>
            </>
        </ProjectContext.Provider>
    )
}

export default Project;