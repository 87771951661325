import React, {memo, useState, useEffect, useMemo} from 'react';

import {RiText as TextIcon} from "react-icons/ri";
import {AiOutlineUnorderedList as ListIcon} from "react-icons/ai";

import Input from "../../../../Components/Input";
import Select from "../../../../Components/Select";
import TotalAmountBadge from "../TotalAmountBadge";

import APIService from "../../../../services/api";
import RemoveRowButton from "./RemoveRowButton";

const OutcomeTransactionItemFormRow = ({item, errors, currency, isPotProject, updateRow, onRowDelete}) => {
  const api = new APIService();

  const [showGoodsSelect, setShowGoodsSelect] = useState(item.goods !== null);

  useEffect(() => {
    !item.goods && !item.name && setShowGoodsSelect(true);
  }, []);

  const GoodsViewIcon = useMemo(() => showGoodsSelect ? TextIcon : ListIcon, [showGoodsSelect]);

  const onGoodsSelect = (option) => {
    if (!option) {
      updateRow('goods', null);
      updateRow('amount', null);
      return;
    }
    updateRow('goods', option);
    updateRow('amount', option.__isNew__ ? '' : option.price);
  }

  const switchGoodsInputView = () => {
    setShowGoodsSelect(state => {
      updateRow('goods', null);
      updateRow('name', null);
      return !state;
    });
  }

  const getSellingPrice = () => {
    if (Number.parseFloat(item.selling_amount)) return item.selling_amount;
    if (!item.goods) return 0;
    return item.goods.sellingPrice || 0;
  }

  return (
    <div className="row transaction-form-row justify-content-center">
      <div className="col-12 col-lg-4 p-0 pr-lg-2">
        <div className="row d-flex justify-content-between">
          <div className="col-1">
            <GoodsViewIcon
              style={{fontSize: '36px', cursor: 'pointer', paddingTop: '16px'}}
              onClick={switchGoodsInputView}
            />
          </div>
          <div className="col-11">
            {showGoodsSelect && <Select
              isAsync
              isCreatable
              isSearchable
              isClearable
              placeholder='Товари/послуги'
              getOptions={api.getGoods}
              defaultValue={item.goods}
              onChange={onGoodsSelect}
              error={errors && errors['goods']}
            />}
            {!showGoodsSelect && <Input
              type='text'
              placeholder={`Назва`}
              value={item.name || ''}
              error={errors && errors['name']}
              onChange={e => updateRow('name', e.target.value)}
            />}
          </div>
        </div>
      </div>
      <div className="col-6 col-lg p-0 pr-2">
        <Input
          type='number'
          placeholder={`Ціна, ${currency}`}
          value={item.amount}
          error={errors && errors['amount']}
          onChange={e => updateRow('amount', e.target.value)}
        />
      </div>
      {!isPotProject && (
        <div className="col-6 col-lg p-0 pl-0 pr-2">
          <Input
            type='number'
            placeholder={`Продажна ціна, ${currency}`}
            value={item.selling_amount}
            error={errors && errors['selling_amount']}
            onFocus={e => updateRow('selling_amount', getSellingPrice())}
            onChange={e => updateRow('selling_amount', e.target.value)}
          />
        </div>
      )}
      <div className={`col-6 col-lg p-0 pl-2 pr-lg-0 pl-lg-0`}>
        <Input
          type='number'
          placeholder='Кількість'
          value={item.quantity}
          error={errors && errors['quantity']}
          onChange={e => updateRow('quantity', e.target.value)}
        />
      </div>
      {!isPotProject && (
        <div className="col-6 col-lg p-0 pl-2">
          <Input
            type='number'
            placeholder='Націнка/знижка, %'
            value={item.extra_charge_percentage}
            onChange={e => updateRow('extra_charge_percentage', e.target.value)}
          />
        </div>
      )}
      <div className="col-12 col-lg-2 d-flex justify-content-end align-items-baseline">
        <TotalAmountBadge
          totalAmount={item.total_amount}
          sellingTotalAmount={item.selling_total_amount}
          currency={currency}
        />
        <RemoveRowButton onClick={() => onRowDelete(item.id)}/>
      </div>
    </div>
  )
}

const propsAreEqual = (prev, next) => {
  return prev.item.goods?.value === next.item.goods?.value
    && prev.item.name === next.item.name
    && prev.item.amount === next.item.amount
    && prev.item.selling_amount === next.item.selling_amount
    && prev.item.quantity === next.item.quantity
    && prev.item.extra_charge_amount === next.item.extra_charge_amount
    && prev.item.extra_charge_percentage === next.item.extra_charge_percentage
    && prev.item.total_amount === next.item.total_amount
    && prev.item.selling_total_amount === next.item.selling_total_amount
    && prev.errors === next.errors;
};

export default memo(OutcomeTransactionItemFormRow, propsAreEqual);