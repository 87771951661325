import React, {memo} from "react";

import './Loader.css';

const Loader = () => (
    <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
    </div>
)

export default memo(Loader, () => true);