import {memo} from "react";

import './Button.css';

const Button = ({text, disabled, onClick, variant = 'success'}) => {
    return (
        <button
            className={`button ${variant}`}
            disabled={disabled}
            onClick={onClick}
        >{text}</button>
    )
}

export default memo(Button);