import React, {useCallback, useEffect, useState} from 'react';

import {useNavigate} from "react-router-dom";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import ukLocale from '@fullcalendar/core/locales/uk';

import APIService from "../../services/api";
import TimesheetWorkerAdd from '../TimesheetWorkerAdd';
import ModalWindow from "../../Components/ModalWindow";
import Button from "../../Components/Button";
import './TimesheetCalendar.css';

const TimesheetCalendar = () => {
    const api = new APIService();
    const navigate = useNavigate();

    const [timesheet, setTimesheet] = useState([]);
    const [datesRange, setDatesRange] = useState([]);
    const [showWorkerAddModal, setShowWorkerAddModal] = useState(false);

    useEffect(() => {
        getTimesheetCalendar();
    }, [datesRange]);

    const openModalWindow = useCallback(() => setShowWorkerAddModal(true), []);

    const toggleModalWindow = useCallback(() => setShowWorkerAddModal(state => !state), []);

    function onDatesChange(dates) {
        const dateFrom = dates.startStr.split('T')[0];
        const dateUntil = dates.endStr.split('T')[0];
        if (dateFrom !== datesRange[0] || dateUntil !== datesRange[1]) {
            setDatesRange([dateFrom, dateUntil]);
        }
    }

    const getTimesheetCalendar = async () => {
        const [dateFrom, dateUntil] = datesRange;
        if (!dateFrom || !dateUntil) return;
        const calendar = await api.getTimesheetCalendar({dateFrom, dateUntil});
        setTimesheet(calendar.map(day => ({
            date: day.date,
            display: 'background',
            backgroundColor: day.unclosed ? '#FF6333' : '#7EF5AD',
        })));
    }

    const onWorkerAdd = async () => {
        await getTimesheetCalendar();
        setShowWorkerAddModal(false);
    }

    return (
        <div className='mt-4'>
            <ModalWindow
                showModal={showWorkerAddModal}
                toggleModal={toggleModalWindow}
            >
                <TimesheetWorkerAdd onWorkerAdd={onWorkerAdd}/>
            </ModalWindow>
            <div className="row d-flex justify-content-center mb-4"><b>Табелі</b></div>
            <div className="row d-flex justify-content-end mb-4">
                <div className="col-12 col-lg-3">
                    <Button
                        text='Додати працівника'
                        onClick={openModalWindow}
                    />
                </div>
            </div>
            <div className='m-xl-4'>
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    locales={[ukLocale]}
                    initialView="dayGridMonth"
                    dateClick={arg => navigate(`/timesheet/${arg.dateStr}`)}
                    events={timesheet}
                    datesSet={onDatesChange}
                    aspectRatio={2.5}
                />
            </div>
        </div>
    )
}

export default TimesheetCalendar;
