import React, {useState, useEffect, useRef} from 'react';
import Button from "../../../Components/Button";
import Loader from "../../../Components/Loader";

const AddAttachment = ({onAddAttachment}) => {
  const [showForm, setShowForm] = useState(false);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    showForm && fileInputRef.current.click();
  }, [showForm]);

  const handleSelectedFile = (e) => {
    const file = e.target.files[0];
    const size = file.size / 1024 / 1024;  // in MB
    if (size > 10) return alert('Файл занадто великий. Максимальний розмір 10 МБ.');
    setFile(file);
    onAddAttachment(file).then(() => {
      setFile(null);
      setShowForm(false);
    });
  };

  if (!showForm) {
    return (
      <div className='row'>
        <div className="col-3 add-attachment">
          <Button
            variant='grey small'
            text='Додати вкладення'
            onClick={() => setShowForm(true)}
          />
        </div>
      </div>
    );
  }
  return (
    <div className='row'>
      <div className="col-4 add-attachment d-flex justify-content-start">
        <>
          {!file && (
            <input
              type="file"
              className='add-attachment-input'
              ref={fileInputRef}
              onChange={handleSelectedFile}
            />
          )}
          {file && (
            <div className='d-flex justify-content-start'>
              <span className='new-attachment-name'>{file.name}</span>
              <Loader/>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default AddAttachment;