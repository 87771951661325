import React, {memo, useState, useEffect} from "react";

import ToggleButton from "../../../Components/ToggleButton";
import APIService from "../../../services/api";
import ProjectTransactionsTableRowItems from "./ProjectTransactionsTableRowItems";
import ProjectTransactionsProjectCells from "./ProjectTransactionsProjectCells";
import ProjectTransactionsPurchaseCells from "./ProjectTransactionsPurchaseCells";

const ProjectTransactionsTableRow = ({transaction, showDetailsGlobal, isPurchase, onSelect}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);

  const api = new APIService();

  useEffect(() => {
    if (showDetails === showDetailsGlobal) return;
    setShowDetails(showDetailsGlobal);
  }, [showDetailsGlobal]);

  useEffect(() => {
    if (!showDetails) return;
    getTransactionDetails();
  }, [showDetails]);

  const toggleDetailsRow = (e) => {
    e.stopPropagation();
    setShowDetails(state => !state);
  }

  const getTransactionDetails = async () => {
    const _transaction = await api.getTransaction(transaction.id);
    setTransactionDetails(_transaction.items.length ? _transaction.items : null);
  }

  const toShowDetails = showDetails && transactionDetails;
  console.log(isPurchase)
  return (
    <>
      <tr onClick={onSelect}>
        <td
          style={{width: '48px'}}
        >
          <ToggleButton
            show={showDetails}
            onClick={toggleDetailsRow}
          />
        </td>
        {isPurchase && <ProjectTransactionsPurchaseCells transaction={transaction}/>}
        {!isPurchase && <ProjectTransactionsProjectCells transaction={transaction}/>}
      </tr>
      {toShowDetails && <ProjectTransactionsTableRowItems items={transactionDetails} isPurchase={isPurchase}/>}
    </>
  )
}

const propsAreEqual = (prev, next) => {
  return prev.showDetailsGlobal === next.showDetailsGlobal &&
    prev.transaction.amount === next.transaction.amount &&
    prev.transaction.status.label === next.transaction.status.label &&
    prev.transaction.description === next.transaction.description;
}

export default memo(ProjectTransactionsTableRow, propsAreEqual);