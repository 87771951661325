import React from 'react';

const PurchaseTable = ({purchase}) => (
  <div className="col-6 d-flex flex-column align-items-start">
    <h1>Закупка №{purchase.id} від {purchase.date}</h1>
    <div style={{borderBottom: '1px solid rgba(0, 0, 0, 0.2)'}}>
      <p><b>Постачальник:</b> {purchase.source}</p>
    </div>
    <div style={{borderBottom: '1px solid rgba(0, 0, 0, 0.2)', width: '100%'}}/>
    <h3>Предмети закупки</h3>
    <hr/>
    {purchase.items.map((item) => (
      <div key={item.id}>
        <p><b>{item.name}</b> - {item.quantity} шт. * {item.price} грн.</p>
      </div>
    ))}
    <div style={{borderBottom: '1px solid rgba(0, 0, 0, 0.2)', width: '100%'}}/>
    <div className='pt-2'>
      <p><b>Всього:</b> {purchase.amount} грн.</p>
    </div>
  </div>
)

export default React.memo(PurchaseTable);
