import React, {useState} from "react";

import Form from "../../Components/Form";
import Input from "../../Components/Input";
import APIService from "../../services/api";
import {useSnackbar} from "../../hooks";

const getToday = () => {
    const today = new Date();
    const month = `${today.getMonth() + 1}`.padStart(2, '0');
    const day = `${today.getDate()}`.padStart(2, '0');
    return `${today.getFullYear()}-${month}-${day}`;
}


const TimesheetWorkerAdd = ({onWorkerAdd}) => {
    const [name, setName] = useState('');
    const [rate, setRate] = useState('');
    const [dateFrom, setDateFrom] = useState(getToday());

    const [errors, setErrors] = useState(null);

    const api = new APIService();
    const openSnackbar = useSnackbar();

    const handleFormSubmit = async () => {
        const workerData = {
            name,
            rate,
            date_from: dateFrom,
        }

        try {
            await api.addWorker(workerData);
            onWorkerAdd();
        } catch (e) {
            console.log(e);
            e.message ? openSnackbar(e.message) : setErrors(e.data);
        }
    }

    return (
        <Form
            headerText='Додавання працівника'
            submitButtonText='Створити'
            onSubmit={handleFormSubmit}
            error={errors && errors['non_field_errors']}
        >
            <div className="row">
                <div className="col">
                    <Input
                        value={name}
                        type={'text'}
                        placeholder='Прізвище та ініціали працівника'
                        error={errors && errors['name']}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Input
                        value={rate}
                        type={'text'}
                        placeholder='Місячна ставка'
                        error={errors && errors['rate']}
                        onChange={e => setRate(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Input
                        value={dateFrom}
                        type={'date'}
                        placeholder='Дата прийому'
                        error={errors && errors['date']}
                        onChange={e => setDateFrom(e.target.value)}
                    />
                </div>
            </div>
        </Form>
    )
}

export default TimesheetWorkerAdd;