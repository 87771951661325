import React, {memo} from 'react';
import ToggleButton from "../../../Components/ToggleButton";

const ProjectTransactionsTableHead = ({showDetails, isPurchase, toggleShowDetails}) => {
  return (
    <tr>
      <td>
        <ToggleButton
          show={showDetails}
          onClick={toggleShowDetails}
        />
      </td>
      <td>#</td>
      <td>Дата</td>
      <td>Призначення</td>
      {isPurchase && <td>Сума закупки</td>}
      {isPurchase && <td>Сума рахунку</td>}
      {!isPurchase && <td>Сума</td>}
      {/*<td>Тип</td>*/}
      <td>Автор</td>
      <td>Статус</td>
      {isPurchase && <td>Завершено</td>}
      <td>Підтверджено</td>
    </tr>
  );
};

export default memo(ProjectTransactionsTableHead);