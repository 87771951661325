import React, {memo} from "react";
import Input from "../../../Components/Input";
import Select from "../../../Components/Select";
import Textarea from "../../../Components/Textarea";
import APIService from "../../../services/api";


const TransactionForm = ({
                           date,
                           payer,
                           description,
                           comment,
                           isOutcome,
                           showPayerField,
                           errors,
                           onTransactionUpdate
                         }) => {
  const api = new APIService();

  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className={`col-12 ${isOutcome ? 'col-lg-4' : 'col-lg-6'}`}>
          <Input
            value={date}
            type={'date'}
            placeholder="Дата"
            error={errors && errors['date']}
            onChange={e => onTransactionUpdate('date', e.target.value)}
          />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className={`col-12 ${isOutcome ? 'col-lg-4' : 'col-lg-6'}`}>
          <Input
            value={description}
            type={'text'}
            placeholder="Обсяги"
            error={errors && errors['description']}
            onChange={e => onTransactionUpdate('description', e.target.value)}
          />
        </div>
      </div>
      {showPayerField && (
        <div className="row d-flex justify-content-center">
          <div className={`col-12 ${isOutcome ? 'col-lg-4' : 'col-lg-6'}`}>
            <Select
              isClearable
              isSearchable
              defaultValue={payer}
              getOptions={api.getPayers}
              placeholder='Платник'
              error={errors && errors['payer']}
              onChange={option => onTransactionUpdate('payer', option)}
            />
          </div>
        </div>
      )}
      <div className="row d-flex justify-content-center">
        <div className={`col-12 ${isOutcome ? 'col-lg-4' : 'col-lg-6'}`}>
          <Textarea
            value={comment || ''}
            type={'text'}
            placeholder='Коментар'
            error={errors && errors['comment']}
            onChange={e => onTransactionUpdate('comment', e.target.value)}
          />
        </div>
      </div>
    </>
  )
}

const propsAreEqual = (prev, next) => {
  return prev.date === next.date &&
    prev.tax === next.tax &&
    prev.description === next.description &&
    prev.payer === next.payer &&
    prev.comment === next.comment &&
    prev.errors === next.errors;
};

export default memo(TransactionForm, propsAreEqual);