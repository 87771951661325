export default class APIService {
    constructor() {
        if (!APIService._instance) {
            APIService._instance = this;
        }
        return APIService._instance;
    }

    authenticate = async ({username, password}) => {
        const response = await fetch('/api/auth/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({username, password}),
        });
        const responseJson = await response.json();
        if (response.status === 200) {
            this.setAuthToken(responseJson.token);
            return true;
        }
        throw new APIError('', responseJson);
    }

    getCurrentUser = async () => this.get('/api/user/');

    getUser = async (userId) => this.get(`/api/user/${userId}/`);

    getUsers = async () => await this.get('/api/user/users/');

    getPayers = async () => await this.get('/api/user/users/?payers=1');

    getProjects = async ({statusFilter = 'open', parentId='', categoriesFilter = []}) => {
        let url = `/api/projects/?status=${statusFilter}&parent_id=${parentId}&categories=${categoriesFilter.join(',')}`;
        return await this.get(url);
    };

    getProjectsOptions = async (searchValue = '') => this.get(`/api/projects/autocomplete/?q=${searchValue}`);

    getProject = async (projectId) => await this.get(`/api/projects/${projectId}/`);

    createProject = async (projectData) => await this.post('/api/projects/', projectData);

    editProject = async (projectId, projectData) => await this.put(`/api/projects/${projectId}/`, projectData);

    getProjectTransactions = async (projectId) => await this.get(`/api/projects/${projectId}/transactions/`);

    getProjectMembers = async (projectId) => await this.get(`/api/projects/${projectId}/members/`);

    addProjectMember = async (projectId, userId) => {
        return await this.post(`/api/projects/${projectId}/members/`, {user_id: userId});
    }

    updateProjectMember = async (projectId, data) => {
        return await this.put(`/api/projects/${projectId}/members/`, data);
    }


    removeProjectMember = async (projectId, userId) => {
        return await this.delete(`/api/projects/${projectId}/members/`, {user_id: userId});
    }

    getProjectMemberGroups = async (projectId) => await this.get(`/api/projects/${projectId}/members/groups/`);

    addProjectMemberGroup = async (projectId, groupId) => {
        return await this.post(`/api/projects/${projectId}/members/groups/`, {group_id: groupId});
    }

    removeProjectMemberGroup = async (projectId, groupId) => {
        return await this.delete(`/api/projects/${projectId}/members/groups/`, {group_id: groupId});
    }

    getProjectHistory = async (projectId) => await this.get(`/api/projects/${projectId}/history/`);

    getCounterparties = async (searchValue = '') => {
        const counterparties = await this.get(`/api/dictionaries/counterparty/?q=${searchValue}`);
        return counterparties.map(c => ({value: c.id, label: c.name}));
    };

    getTIMCounterparties = async (searchValue = '') => {
        const counterparties = await this.get(`/api/dictionaries/counterparty/?q=${searchValue}&is_tim=1`);
        return counterparties.map(c => ({value: c.id, label: c.name}));
    };

    getCurrencies = async () => {
        const currencies = await this.get('/api/dictionaries/currency/');
        return currencies.map(c => ({value: c.id, label: c.name}));
    };

    getProjectCategories = async () => {
        const categories = await this.get('/api/dictionaries/category/');
        return categories.map(c => ({value: c.id, label: c.name}));
    };

    getTaxRates = async () => {
        const taxRates = await this.get('/api/dictionaries/tax/');
        return taxRates.map(t => ({value: t.id, label: `${t.rate} %`}));
    };

    addTransaction = async (projectId, data) => await this.post(`/api/projects/${projectId}/transactions/`, data);

    getTransaction = async (transactionId) => await this.get(`/api/projects/transaction/${transactionId}/`);

    getTransactionsAutocomplete = async (searchValue = '', type = 1) => await this.get(`/api/projects/transaction/autocomplete/?q=${searchValue}&type=${type}`);

    updateTransaction = async (transactionId, data) => await this.put(`/api/projects/transaction/${transactionId}/`, data);

    confirmTransaction = async (transactionId) => await this.post(`/api/projects/transaction/${transactionId}/confirm/`);

    updateTransactionStatus = async (transactionId, data) => await this.post(`/api/projects/transaction/${transactionId}/change_status/`, data);

    deleteTransaction = async (transactionId) => await this.delete(`/api/projects/transaction/${transactionId}/`);

    copyTransaction = async (transactionId, data) => await this.post(`/api/projects/transaction/${transactionId}/copy/`, data);
    importTransactionFromSklad = async (transactionId, data) => await this.post(`/api/projects/transaction/${transactionId}/import/sklad/`, data);

    deleteTransactionItem = async (transactionItemId) => await this.delete(`/api/projects/transaction_item/${transactionItemId}/`);

    exportProjectToExcel = async (projectId) => {
        const authToken = this.getAuthToken();

        const response = await fetch(`/api/projects/${projectId}/export_to_xls/`, {
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) return await response.blob();

        const responseJson = await response.json();
        throw new APIError(responseJson['error']);
    }

    getPurchaseData = async (purchaseId) => await this.get(`/api/projects/transaction/link/sklad/?purchase_id=${purchaseId}`);

    getBills = async (page, users, counterparty) => await this.get(`/api/projects/bills/?page=${page}&users=${users.join(',')}&counterparty=${counterparty || ''}`);

    getBillsAutocomplete = async (searchValue = '') => await this.get(`/api/projects/bill/autocomplete/?q=${searchValue}`);;

    linkPurchase = async (transactionId, purchaseId) => await this.post(`/api/projects/transaction/link/sklad/`, {transaction_id: transactionId, purchase_id: purchaseId});
    getGoods = async (searchParam) => {
        const goods = await this.get(`/api/dictionaries/goods/?q=${searchParam || ''}`);
        return goods.map(g => ({
            value: g.id,
            label: g.name,
            price: g.price,
            sellingPrice: g.selling_price,
            isFixedPrice: g.is_fixed_price,
        }));
    };

    getGoodsItem = async (itemId) => {
        const item = await this.get(`/api/dictionaries/goods/${itemId}/`);
        return {
            value: item.id,
            label: item.name,
            price: item.price,
            sellingPrice: item.selling_price,
            isFixedPrice: item.is_fixed_price,
        };
    };

    updateGoodsItem = async (itemId, data) => {
        const item = await this.put(`/api/dictionaries/goods/${itemId}/`, data);
        return {
            value: item.id,
            label: item.name,
            price: item.price,
            sellingPrice: item.selling_price,
        };
    }

    uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', file.name);
        const response = await fetch('/api/files/upload/', {
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.getAuthToken()}`,
            },
            body: formData,
        });
        const responseJson = await response.json();
        if (response.status === 201) return responseJson;
        throw new APIError('', responseJson);
    }

    addTransactionAttachment = async (transactionId, attachmentId) => await this.post(
      `/api/projects/transaction/${transactionId}/attachment/`,
      {attachment_id: attachmentId},
    );

    deleteTransactionAttachment = async (transactionId, attachmentId) => await this.delete(
      `/api/projects/transaction/${transactionId}/attachment/`,
      {attachment_id: attachmentId},
    );

    getTimesheetCalendar = async ({dateFrom, dateUntil}) => {
        const url = `/api/timesheet/calendar/?date_from=${dateFrom}&date_until=${dateUntil}`;
        return await this.get(url);
    };

    getTimesheet = async (date) => await this.get(`/api/timesheet/?date=${date}`);

    splitTimesheetItem = async (itemId) => await this.post(`/api/timesheet/${itemId}/`);

    editTimesheetItem = async (itemId, data) => await this.put(`/api/timesheet/${itemId}/`, data);

    addWorker = async (workerData) => await this.post('/api/timesheet/worker/', workerData);

    getWorker = async (workerId) => await this.get(`/api/timesheet/worker/${workerId}/`);

    editWorker = async (workerId, workerData) => await this.put(`/api/timesheet/worker/${workerId}/`, workerData);

    get = async (url) => {
        const authToken = this.getAuthToken();

        const response = await fetch(url, {
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json',
            },
        });

        const responseJson = await response.json();
        if (response.status === 200) {
            return responseJson;
        }
        if (response.status === 401) {
            throw new APIAuthenticationError('Час сесії минув. Будь ласка, увійдіть повторно.');
        }
        throw new APIError(responseJson['error']);
    }

    post = async (url, data) => {
        const authToken = this.getAuthToken();

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const responseJson = await response.json();
        if (response.status === 200 || response.status === 201) {
            return responseJson;
        }

        if (responseJson['error']) {
            throw new APIError(responseJson['error']);
        }
        throw new APIError('', responseJson);
    }

    put = async (url, data) => {
        const authToken = this.getAuthToken();

        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const responseJson = await response.json();
        if (response.status === 200) {
            return responseJson;
        }
        if (responseJson['error']) {
            throw new APIError(responseJson['error']);
        }
        throw new APIError('', responseJson);
    }

    delete = async (url, data) => {
        const authToken = this.getAuthToken();

        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.status === 204) {
            return true;
        }
        const responseJson = await response.json();
        throw new APIError(responseJson['error']);
    }

    getAuthToken = () => {
        const authToken = localStorage.getItem('auth_token');
        if (authToken) {
            return authToken;
        }
        throw new APIAuthenticationError('Not authenticated');
    };

    setAuthToken = (authToken) => localStorage.setItem('auth_token', authToken);
}

class APIError extends Error {
    constructor(message, data) {
        super(message);
        this.data = data || null;
    }
}

class APIAuthenticationError extends APIError {
}

export {
    APIError,
    APIAuthenticationError,
}