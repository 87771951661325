import React from 'react';

const Bill = ({bill}) => {
  return (
    <>
      <h4>Рахунок</h4>
      <div className="row flex-column" style={{textAlign: 'left'}}>
        <div className="col"><p><b>Постачальник:</b> {bill.counterparty.label || ''}</p></div>
        {bill.tim_counterparty && <div className="col"><p><b>Покупець:</b> {bill.tim_counterparty.label}</p></div>}
        <div className="col"><p><b>Рахунок:</b>№ {bill.number || ''} від {bill.date || ''}</p></div>
        <div className="col"><p><b>Реквізити:</b> {bill.details || '-'}</p></div>
      </div>
      <hr/>
    </>
  )
}

export default Bill;
