import React, {useRef, useState, memo} from 'react';
import {GiConfirmed} from "react-icons/gi";
import {HiDotsVertical} from "react-icons/hi";
import {ControlledMenu, MenuItem} from "@szhsin/react-menu";
import '@szhsin/react-menu/dist/index.css';

const TimesheetDayRowMenu = ({isClosed, onCloseTimesheet, onoOpenTimesheet, onSplitTimesheet}) => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState();
    return (
        <div className='col d-flex justify-content-end' style={{cursor: 'pointer'}}>
            {!isClosed && (<GiConfirmed
                style={{color: '#00B28E', fontSize: '40px', paddingLeft: '16px'}}
                onClick={onCloseTimesheet}
            />)}
            <div ref={ref} className="btn" onClick={() => setOpen(true)}>
                <HiDotsVertical style={{fontSize: '40px',paddingLeft: '16px'}}/>
            </div>
            <ControlledMenu
                state={isOpen ? 'open' : 'closed'}
                anchorRef={ref}
                onPointerLeave={() => setOpen(false)}
                onClose={() => setOpen(false)}
                direction={'left'}
            >
                {isClosed && <MenuItem onClick={onoOpenTimesheet}>Редагувати</MenuItem>}
                {!isClosed && <MenuItem onClick={onSplitTimesheet}>Розділити</MenuItem>}
            </ControlledMenu>
        </div>
    )
}

export default memo(TimesheetDayRowMenu)
