import React, {memo, useEffect} from 'react';

import IncomeTransactionItemFormRow from './IncomeTransactionItemFormRow';
import OutcomeTransactionItemFormRow from './OutcomeTransactionItemFormRow';

import APIService from "../../../../services/api";

const TransactionItemFormRow = ({item, isOutcome, isPotProject, currency, errors, onRowUpdate, onRowDelete}) => {
    const api = new APIService();

    useEffect(() => {
        calculateTotalAmount();
    }, [item.amount, item.selling_amount, item.quantity, item.extra_charge_percentage, item.extra_charge_amount]);

    const updateRow = (column, value) => onRowUpdate(item.id, column, value);

    const calculateTotalAmount = async () => {
        if (!item.amount || !item.quantity) {
            return updateRow(item.id, 'total_amount', (0).toFixed(2));
        }

        const response = await api.post('/api/projects/transaction/calc/', {
            amount: parseFloat(item.amount) || 0,
            selling_amount: parseFloat(item.selling_amount) || 0,
            quantity: item.quantity || 0,
            extra_charge_percentage: item.extra_charge_percentage || 0,
            extra_charge_amount: item.extra_charge_amount || 0,
        });
        updateRow('total_amount', response.total_amount.toFixed(2));
        updateRow('selling_total_amount', response.selling_total_amount.toFixed(2));
    }

    if (!isOutcome) {
        return <IncomeTransactionItemFormRow
            item={item}
            errors={errors}
            currency={currency}
            updateRow={updateRow}
            onRowDelete={onRowDelete}
        />
    }

    return (
        <OutcomeTransactionItemFormRow
            item={item}
            errors={errors}
            currency={currency}
            isPotProject={isPotProject}
            updateRow={updateRow}
            onRowDelete={onRowDelete}
        />
    )
}

export default memo(TransactionItemFormRow);
