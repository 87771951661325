import React from 'react';

const BillsListEmptyTable = () => {
  return (
    <tr>
      <td colSpan={4}>
        <b>Рахунки відсутні.</b>
      </td>
    </tr>
  );
};

export default BillsListEmptyTable;