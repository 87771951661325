import React, {memo} from 'react';

const ProjectListTableHead = () => {
    return (
        <tr>
            <td></td>
            <td>#</td>
            <td>Назва</td>
            <td>Баланс</td>
            <td>Контрагент</td>
            <td>Керівник</td>
            <td>Створено</td>
        </tr>
    );
};

export default memo(ProjectListTableHead, () => true);