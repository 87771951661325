import React, {useState, useEffect, useContext, useMemo} from "react";

import {useParams} from "react-router-dom";


import ProjectTransactionCopy from "../ProjectTransactionCopy";
import SkladImportMenu from "./SkladImportMenu";
import CloseTransactionModalWindow from "./CloseTransactionModalWindow";
import TransactionFormSet from "./TransactionFormSet";
import TransactionReadOnlyTable from "./ReadOnlyTable";
import ProjectTransactionMenuRow from "./ProjectTransactionMenuRow";
import Loader from "../../Components/Loader";
import {UserContext} from "../../context";

import APIService from "../../services/api";
import {useSnackbar} from "../../hooks";

import {ProjectContext} from "../../context";
import ModalWindow from "../../Components/ModalWindow";

import './ProjectTransaction.css';


const ProjectTransaction = () => {
  const [showProjectTransactionCopyModal, setShowProjectTransactionCopyModal] = useState(false);
  const [showSkladImportModal, setShowSkladImportModal] = useState(false);
  const [showCloseTransactionModal, setShowCloseTransactionModal] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [transactionItems, setTransactionItems] = useState(null);
  const [bill, setBill] = useState(null);
  const [errors, setErrors] = useState(null);
  const {getProject} = useContext(ProjectContext);
  const urlParams = useParams();
  const openSnackbar = useSnackbar();

  const api = new APIService();

  const {getCurrentUser} = useContext(UserContext);
  const {project} = useContext(ProjectContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    getTransaction();
  }, []);

  const showPayerField = useMemo(() => {
    if (!transaction || !project) return false;
    const projectIsPot = project.is_pot;
    const isOutcome = transaction.type === 'Витрати';
    if (projectIsPot && !isOutcome) return true;
    return !projectIsPot && isOutcome;
  }, [transaction, project]);

  const getTransaction = async () => {
    let transaction;
    try {
      transaction = await api.getTransaction(urlParams.transactionId);
    } catch (e) {
      openSnackbar(e.message);
    }

    if (!transaction) return;

    const {items, outcome_bill, ...rest} = transaction;
    setTransaction(rest);
    setTransactionItems(items);
    setBill(outcome_bill);
  }

  async function saveTransaction() {
    const items = {};
    for (let i = 0; i < transactionItems.length; i++) {
      const item = {...transactionItems[i]};
      item.goods = item.goods ? item.goods.value : null;
      if (!item.goods && !item.quantity && !item.amount) {
        continue
      }
      if (!item.selling_amount) item.selling_amount = 0;
      item.id = item.id || `new_${i}`;
      delete item.total_amount;
      delete item.selling_total_amount;
      items[item.id] = item;
    }

    const data = {
      date: transaction.date,
      comment: transaction.comment,
      description: transaction.description,
      tax: transaction.tax.value,
      payer: transaction.payer ? transaction.payer.value : null,
      items,
    }

    if (bill) {
      data.bill = {
        counterparty: bill.counterparty ? bill.counterparty.value : null,
        tim_counterparty: bill.tim_counterparty ? bill.tim_counterparty.value : null,
        number: bill.number,
        date: bill.date,
        details: bill.details,
      }
    }

    return await processTransaction(data);
  }

  const closeTransaction = async () => {
    const isSuccessful = transaction.roles.is_manager ? await saveTransaction() : true;
    if (!isSuccessful) return;
    const payerIsMainAccountant = transaction.payer && transaction.roles.is_main_accountant;
    const needPayerLegalEntity = payerIsMainAccountant && bill && !bill.tim_counterparty;
    if (needPayerLegalEntity) {
      setShowCloseTransactionModal(true);
    } else {
      await processTransactionStatusChange(3);
    }
  }

  const closeTransactionWithPayerLegalEntity = async (timCounterparty) => {
    const data = {
      status: 3,
      tim_counterparty: timCounterparty,
    }
    api.updateTransactionStatus(urlParams.transactionId, data)
      .then(() => getTransaction())
      .then(() => setShowCloseTransactionModal(false))
      .then(() => getProject(transaction.project))
      .then(() => getCurrentUser())
      .then(() => setErrors(null))
      .catch((e) => e.data ? setErrors(e.data) : openSnackbar(e.message));
  }

  const reOpenTransaction = async () => {
    await processTransactionStatusChange(2);
  }
  const confirmTransaction = async () => {
    api.confirmTransaction(urlParams.transactionId)
      .then(() => getTransaction())
      .then(() => getCurrentUser())
      .catch((e) => e.data ? setErrors(e.data) : openSnackbar(e.message));
  }

  const processTransaction = async (data) => {
    try {
      await api.updateTransaction(urlParams.transactionId, data);
      await getTransaction();
      await getProject(transaction.project);
    } catch (e) {
      e.data ? setErrors(e.data) : openSnackbar(e.message);
      return false;
    }
    setErrors(null);
    return true;
  }

  const processTransactionStatusChange = async (status) => {
    api.updateTransactionStatus(urlParams.transactionId, {status})
      .then(() => getTransaction())
      .then(() => getProject(transaction.project))
      .then(() => getCurrentUser())
      .then(() => setErrors(null))
      .catch((e) => e.data ? setErrors(e.data) : openSnackbar(e.message));
  };

  const importTransactionFromSklad = async (purchaseId) => {
    api.importTransactionFromSklad(urlParams.transactionId, {'purchase_id': purchaseId})
      .then(() => getTransaction())
      .then(() => getProject(transaction.project))
      .then(() => getCurrentUser())
      .then(() => setErrors(null))
      .then(() => setShowSkladImportModal(false))
      .catch((e) => e.data ? setErrors(e.data) : openSnackbar(e.message));
  }

  const exportTransactionToSklad = () => {
    const url = 'http://sklad.itim.net/base/purchase/add/?moneypot_id=' + transaction.id;
    window.open(url, '_blank').focus();
  }

  if (transaction === null) return <Loader/>;

  const isOutcome = transaction.type === 'Витрати';
  const isManager = transaction.roles.is_manager;
  const isPayer = transaction.roles.is_payer;
  const canConfirm = transaction.roles.can_confirm;
  const isClosed = transaction.status.value === 3;
  return (
    <>
      <ModalWindow
        showModal={showProjectTransactionCopyModal}
        toggleModal={() => setShowProjectTransactionCopyModal(!showProjectTransactionCopyModal)}
      >
        <ProjectTransactionCopy transactionId={transaction.id}/>
      </ModalWindow>
      <ModalWindow
        showModal={showSkladImportModal}
        toggleModal={() => setShowSkladImportModal(!showSkladImportModal)}
      >
        <SkladImportMenu onSubmit={importTransactionFromSklad}/>
      </ModalWindow>
      <ModalWindow
        showModal={showCloseTransactionModal}
        toggleModal={() => setShowCloseTransactionModal(!showCloseTransactionModal)}
      >
        <CloseTransactionModalWindow onSubmit={closeTransactionWithPayerLegalEntity}/>
      </ModalWindow>
      <ProjectTransactionMenuRow
        onCopyTransactionButtonPress={isManager && (() => setShowProjectTransactionCopyModal(true))}
        onShowSkladImportModalButtonPress={isOutcome && !isClosed && (() => setShowSkladImportModal(true))}
        onReOpenTransactionButtonPress={isClosed && isPayer ? reOpenTransaction : null}
        onCloseTransactionButtonPress={!isClosed && isPayer ? closeTransaction : null}
        onConfirmTransactionButtonPress={canConfirm ? confirmTransaction : null}
        onExportTransactionToSkladButtonPress={isOutcome ? exportTransactionToSklad : null}
      />
      {!transaction.editable && (
        <TransactionReadOnlyTable
          transaction={transaction}
          transactionItems={transactionItems}
          bill={bill}
          isOutcome={isOutcome}
        />
      )}
      {transaction.editable && (
        <TransactionFormSet
          transaction={transaction}
          transactionItems={transactionItems}
          bill={bill}
          errors={errors}
          isOutcome={isOutcome}
          showPayerField={showPayerField}
          setTransaction={setTransaction}
          setTransactionItems={setTransactionItems}
          setBill={setBill}
          onFormSubmit={saveTransaction}
        />
      )}
    </>
  );
}

export default ProjectTransaction;