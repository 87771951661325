import React, {useState} from "react";

import Loader from "../../Components/Loader";
import ModalWindow from "../../Components/ModalWindow";
import ProjectListTable from './ProjectListTable';
import ProjectsMenu from './ProjectsMenu';
import ProjectAdd from "../ProjectAdd";
import APIService from "../../services/api";
import {useSnackbar} from "../../hooks";

import './ProjectsList.css';
import Breadcrumbs from "../../Components/Breadcrumbs";
import {Link, useParams} from "react-router-dom";


const ProjectsList = () => {
  const [projects, setProjects] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);

  const params = useParams();

  const api = new APIService();
  const openSnackbar = useSnackbar();

  const searchedProjects = React.useMemo(() => {
    if (!projects) return null;
    if (!searchValue) return projects;

    const val = searchValue.toLowerCase();
    const projectIsMatched = (project) => {
      const name = project.name.toLowerCase();
      const id = project.id.toString().toLowerCase();
      return id.includes(val) || name.includes(val);
    }

    const searchProjects = (projects) => {
      return projects.map(project => {
        const isMatched = projectIsMatched(project);
        const matchedChildren = searchProjects(project.children);
        if (!isMatched && !matchedChildren.length) return null;
        return {...project, children: matchedChildren};
      }).filter(project => project);
    };

    return searchProjects(projects);
  }, [projects, searchValue]);

  const onFiltersChange = (filters) => {
    setProjects(null);
    getProjects(filters);
  }

  const getProjects = async ({statusFilter = 'open', categoriesFilter = []} = {}) => {
    const parentId = params.projectId || '';
    try {
      setProjects(await api.getProjects(
        {statusFilter, parentId, categoriesFilter}
      ));
    } catch (e) {
      openSnackbar(e.message);
    }
  }

  return (
    <>
      <div className="row">
        <Breadcrumbs>
          <Link to='/projects'>Проекти</Link>
        </Breadcrumbs>
      </div>
      <div className='projects-list'>
        <ModalWindow
          showModal={showAddProjectModal}
          toggleModal={() => setShowAddProjectModal(!showAddProjectModal)}
        >
          <ProjectAdd/>
        </ModalWindow>
        <ProjectsMenu
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          onFiltersChange={onFiltersChange}
          onShowAddProjectModalButtonClick={() => setShowAddProjectModal(true)}
        />
        <div className="row">
          <div className="col">
            {!searchedProjects ? <Loader/> : <ProjectListTable
              projects={searchedProjects}
              showChildren={searchValue.length > 0}
            />}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectsList;