import React from 'react';

const ProjectTransactionsProjectCells = ({transaction}) => {
  const isIncomeTransaction = transaction.type === 'Дохід';
  return (
    <>
      <td>{transaction.id}</td>
      <td>{transaction.date}</td>
      <td style={{overflow: 'hidden'}} title={transaction.description}>{transaction.description}</td>
      <td className={`${isIncomeTransaction ? 'table-positive-value' : 'table-negative-value'}`}>
        {isIncomeTransaction ? '' : '-'}{transaction.amount}
      </td>
      <td>{transaction.manager}</td>
      <td>{transaction.status.label}</td>
      <td>{transaction.is_confirmed ? <span className='confirm-badge'>&#10003;</span> :
        <span className='not-confirm-badge'>&#10007;</span>}</td>
    </>
  );
};

export default ProjectTransactionsProjectCells;