import React, {useEffect, useState} from "react";

import {Outlet, useNavigate, useLocation} from "react-router-dom";
import Header from "../../Components/Header";
import AsidePanel from "../../Components/AsidePanel";
import Loader from "../../Components/Loader";
import {UserContext} from "../../context";
import APIService, {APIAuthenticationError} from "../../services/api";

import './Home.css';

const HomePage = () => {
    const [user, setUser] = useState(null);
    const api = new APIService();
    const navigate = useNavigate();
    const location = useLocation();

    const intervalRef = React.useRef();

    useEffect(() => {
        getCurrentUser();

        intervalRef.current = setInterval(getCurrentUser, 60000);

        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        user && user.permissions.view_projects && location.pathname === '/' && navigate('/projects');
    }, [user]);

    const getCurrentUser = async () => {
        try {
            setUser(await api.getCurrentUser());
        } catch (e) {
            if (e instanceof APIAuthenticationError) logout();
        }
    };

    const logout = () => {
        localStorage.clear();
        navigate('/auth', {replace: true});
    };

    if (!user) {
        return (
            <div className="App">
                <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                    <Loader/>
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid">
            <UserContext.Provider value={{user, getCurrentUser}}>
                <Header
                    onLogoutButtonPress={logout}
                />
                <main className='main row'>
                    <div className="content col">
                        <div className="row">
                            <div className="d-none d-lg-block col-lg-2 p-0">
                                <AsidePanel
                                    projects={user.projects}
                                    toConfirm={user.transactions_to_confirm}
                                    toPay={user.transactions_to_pay}
                                    myTransactions={user.my_current_transactions}
                                    timesheet={user.timesheet}
                                />
                            </div>
                            <div className="col-12 col-lg-10">
                                <Outlet/>
                            </div>
                        </div>
                    </div>
                </main>
            </UserContext.Provider>
        </div>
    )
}

export default HomePage;