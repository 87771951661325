import React, {useState} from "react";

import Form from "../../Components/Form";
import Input from "../../Components/Input";

const SkladImportMenu = ({onSubmit}) => {
  const [purchaseId, setPurchaseId] = useState('');

  return (
    <Form
      headerText='Імпорт зі складу'
      submitButtonText='Імпортувати'
      onSubmit={() => onSubmit(purchaseId)}
    >
      <div className="row">
        <div className="col">
          <Input
            value={purchaseId}
            type={'text'}
            placeholder="Номер закупки зі складу"
            onChange={e => setPurchaseId(e.target.value)}
          />
        </div>
      </div>
    </Form>
  )
}

export default SkladImportMenu;