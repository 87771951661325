import React, {useState, useEffect, useContext, useReducer} from "react";

import {useParams} from "react-router-dom";

import Form from "../../Components/Form";
import Input from "../../Components/Input";
import Switch from "../../Components/Switch";
import APIService from "../../services/api";
import {ProjectContext} from "../../context";
import {useSnackbar} from "../../hooks";
import Select from "../../Components/Select";
import Loader from "../../Components/Loader";


const ProjectEdit = () => {
  const [projectState, dispatch] = useReducer(
    (state, newState) => ({...state, ...newState}),
    null,
  );

  const [errors, setErrors] = useState(null);

  const {project, getProject} = useContext(ProjectContext);
  const openSnackbar = useSnackbar();

  const urlParams = useParams();
  const api = new APIService();

  useEffect(() => {
    project && dispatch({
      name: project.name,
      tax: project.tax,
      counterparty: project.counterparty,
      status: project.status,
      parent: project.parent,
      category: project.category,
    });
  }, [project]);

  const handleFormSubmit = async () => {
    if (projectState.status && !window.confirm('Ви дійсно бажаєте закрити проект?')) {
      return;
    }

    const {
      name,
      status,
      tax: {value: tax},
      counterparty: {value: counterparty},
    } = projectState;
    const parent = projectState.parent?.value || null;
    const category = projectState.category?.value || null;
    try {
      await api.editProject(urlParams.projectId, {name, status, tax, counterparty, parent, category});
    } catch (e) {
      e.data ? setErrors(e.data) : openSnackbar(e.message);
      return;
    }
    setErrors(null);
    await getProject(project.id);
    openSnackbar('Налаштування проекту збережено.');
  }

  if (!projectState) return <Loader/>;

  if (project && project.status === 1) {
    return (
      <div className='row'>
        <div className="col">Проект закрито. Редагування заборонено.</div>
      </div>
    )
  }

  console.log('projectState.parent', projectState.parent)

  return (
    <div className='row d-flex justify-content-center'>
      <div className="col-12 col-lg-4">
        <Form
          headerText='Редагування проекту'
          submitButtonText='Підтвердити'
          onSubmit={handleFormSubmit}
          error={errors && errors['non_field_errors']}
        >
          <div className="row">
            <div className="col">
              <Input
                value={projectState.name}
                type={'text'}
                placeholder='Назва проекту'
                error={errors && errors['name']}
                onChange={e => dispatch({name: e.target.value})}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Select
                isCreatable
                isSearchable
                defaultValue={projectState.counterparty}
                getOptions={api.getCounterparties}
                placeholder='Контрагент'
                error={errors && errors['counterparty']}
                onChange={option => dispatch({counterparty: option || null})}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Select
                isAsync
                isSearchable
                isClearable
                placeholder='Батьківський проект'
                error={errors && errors['parent']}
                getOptions={api.getProjectsOptions}
                defaultValue={projectState.parent}
                onChange={option => dispatch({parent: option || null})}
              />
            </div>
          </div>
          {projectState.parent === null && (
            <div className="row">
              <div className="col">
                <Select
                  isSearchable
                  placeholder='Категорія'
                  error={errors && errors['category']}
                  getOptions={api.getProjectCategories}
                  defaultValue={projectState.category}
                  onChange={option => dispatch({category: option || null})}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              <Select
                defaultValue={projectState.tax}
                getOptions={api.getTaxRates}
                placeholder='Ставка податку'
                error={errors && errors['tax']}
                onChange={option => dispatch({tax: option || null})}
              />
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-start">
              <Switch
                label='Закрити'
                checked={projectState.status}
                onChange={isChecked => dispatch({status: +isChecked})}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default ProjectEdit;