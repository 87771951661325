import React, {useContext} from "react";

import Table from "../../../Components/Table";
import {ProjectContext} from "../../../context";

const OutcomeTransactionReadOnlyTable = ({transactionAmount, transactionItems}) => {
    const {project} = useContext(ProjectContext);
    const {currency} = project;

    return (
        <Table
            rows={transactionItems}
            renderHead={() => (
                <tr>
                    <td>Назва</td>
                    <td>Ціна</td>
                    <td>Всього</td>
                </tr>
            )}
            renderRow={item => (
                <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.amount} {currency}</td>
                    <td><b>{item.total_amount} {currency}</b></td>
                </tr>
            )}
            renderEmpty={() => <tr><td colSpan={3}>-</td></tr>}
            renderBottom={() => (
                <tr>
                    <td colSpan={3}>
                        <b>Всього: {transactionAmount} {currency}</b>
                    </td>
                </tr>
            )}
        />
    )
}

export default OutcomeTransactionReadOnlyTable;
