import React, {useState} from 'react';

import {useNavigate, useSearchParams} from 'react-router-dom';

import APIService from "../../services/api";
import Select from "../../Components/Select";
import Button from "../../Components/Button";
import PurchaseTable from "./PurchaseTable";
import TransactionTable from "./TransactionTable";
import {useSnackbar} from "../../hooks";

const ProjectTransactionSkladLink = () => {
  const [purchaseData, setPurchaseData] = React.useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [transaction, setTransaction] = useState(null);

  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  const openSnackbar = useSnackbar();
  const api = new APIService();

  React.useEffect(() => {
    const purchaseId = searchParams.get('purchase_id');
    if (!purchaseId) return;

    api.getPurchaseData(purchaseId)
      .then((response) => setPurchaseData(response))
      .catch((err) => openSnackbar(err.message));
  }, []);

  React.useEffect(() => {
    if (!transactionId) {
      return setTransaction(null);
    }

    api.getTransaction(transactionId)
      .then(tr => setTransaction(tr))
      .catch((err) => openSnackbar(err.message));
  }, [transactionId]);

  const linkPurchase = () => {
    if (!transactionId) return;

    api.linkPurchase(transactionId, purchaseData.id)
      .then((data) => navigate(`/project/${data.project_id}/transaction/${data.id}`))
      .catch((err) => openSnackbar(err.message));
  };

  if (!purchaseData) return null;

  return (
    <>
      <div className='row d-flex justify-content-end pt-4'>
        {/*<div class="col-2 pt-3 d-flex justify-content-end">*/}
        {/*  <Link to='/projects/bills' style={{color: 'rgba(0, 0, 0, 0.5)'}}><b>Пошук рахунків</b></Link>*/}
        {/*</div>*/}
        <div className="col-2">

          <Button
            text="Зв'язати з транзакцією"
            disabled={!transactionId}
            onClick={linkPurchase}
          />
        </div>
      </div>
      <div className='row pt-4'>
        <PurchaseTable purchase={purchaseData} />
        <div className="col-6">
          <div className="row">
            <div className="col pb-4">
              <Select
                isAsync
                isClearable
                isSearchable
                placeholder='№ транзакції'
                error={null}
                getOptions={api.getTransactionsAutocomplete}
                onChange={option => setTransactionId(option ? option.value : null)}
              />
            </div>
          </div>
          <TransactionTable transaction={transaction}/>
        </div>
      </div>
    </>
  );
};

export default ProjectTransactionSkladLink;
